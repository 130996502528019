<template>
  <div style="overflow: hidden"> 
    <main v-if="!ready">
      <div class="d-flex flex-column mat24">
          <h2 class="text-center">
              {{ say.results_are_loading }}
          </h2>
          <div class="mat16 lds-ellipsis lds-ellipsis--center">
              <div />
              <div />
              <div />
          </div>
      </div>
    </main>
    <div v-else>
      <div class="container">
        <!-- Competition name. -->
        <div
          class="row mat16"
        >
          <div class="col-12 col-md-8">
            <h2>{{ competition.name }} - {{ classification.namePl }}</h2>
          </div>
          <div class="col-12 col-md-4 d-flex justify-content-end">
            <!-- <base-button-group
              horizontal
              class="mar30 h100"
            >
              <base-button
                  btn11
                  badge="far fa-home"
                  class="competition-topbar__btn competition-topbar__btn--1"
                  :pressed="$route.name === 'panel-competition-dashboard'"
                  @click="
                      $router.push({
                          name: 'panel-competition-dashboard',
                          params: competition,
                          alias: true,
                      })
                  "
              />
              <base-button
                  v-if="competition.isRemote === false"
                  btn11
                  badge="fas fa-list-ol"
                  class="nowrap competition-topbar__btn competition-topbar__btn--4"
                  @click="
                      $router.push({
                          name: 'panel-competition-result',
                          params: competition,
                          alias: true,
                      })
                  "
              >
                  Wyniki
              </base-button>
            </base-button-group> -->
            <base-button
              btn9
              :icon="
                  isZenMode
                      ? 'fas fa-compress-alt'
                      : 'fas fa-expand-alt'
              "
              @click="SET_ZEN_MODE(!isZenMode)"
              />
          </div>
        </div>

      </div>
      <div class="container-result">
        <base-table
            v-if="items.length"
            class="table-list"
            :headers="headers"
            :items="items"
            sortColumnDefault="0"
            :sortReverseDefault="false"
        >
            <template v-slot:header="{ sort }">
                <tr class="table-list__row bg--dark">
                    <th
                        v-for="(header, column) in headers"
                        :key="column"
                        class="table-list__header"
                        :class="{
                            'table-list__header--second-cell':
                                column === 1,
                            'table-list__header--first-cell':
                                column === 0,
                            'd-none':
                                column === 1 &&
                                competition.isRemote &&
                                competition.id !== 4877,
                        }"
                        @click="sort(column)"
                    >
                        <template
                            v-if="
                                !isSplitTime &&
                                    column === headers.length - 2 &&
                                    !competition.isRemote
                            "
                        >
                            {{ header.text }}(lap)
                        </template>
                        <template v-else>
                            {{ header.text }}
                        </template>
                    </th>
                </tr>
            </template>

            <template v-slot:body="{ displayedItems }">
                <tr
                    v-for="item in displayedItems"
                    :key="item.id"
                    class="table-list__row"
                    :class="{ 'hidden' : item.position === null }"
                >
                    <td
                        v-for="(header, column) in headers"
                        :key="column"
                        class="table-list__data"
                        :class="{
                            'table-list__data--second-cell':
                                column === 1,
                            'table-list__data--first-cell':
                                column === 0,
                            'd-none':
                                column === 1 &&
                                competition.isRemote &&
                                competition.id !== 4877,
                            'table-list__data--right-border':
                                column === 0 &&
                                competition.isRemote &&
                                competition.id !== 4877,
                            'table-list__data--padding':
                                header.value === 'type',
                            'align-right':
                                header.value === 'isDeclaredTime',
                        }"
                    >
                        <p
                            v-if="
                                header.value === 'isDeclaredTime' &&
                                    item.isDeclaredTime
                            "
                            class="table-list__data--info-time"
                        >
                            {{ say.declared_time }}
                        </p>
                        <p
                            v-else-if="
                                header.value ===
                                    'isDeclaredDistance' &&
                                    item.isDeclaredDistance
                            "
                            class="table-list__data--info-time"
                        >
                            {{ say.declared_distance }}
                        </p>
                        <p
                            v-else-if="
                                (header.value ===
                                    'isDeclaredTime' &&
                                    !item.isDeclaredTime) ||
                                    (header.value ===
                                        'isDeclaredDistance' &&
                                        !item.isDeclaredDistance)
                            "
                        />
                        <template
                            v-else-if="
                                header.value === 'distance' &&
                                    item[header.value]
                            "
                        >
                            {{ `${item[header.value]} km` }}
                        </template>
                        <template 
                            v-else-if="column === 0"
                        >
                          {{ item.position }} 
                        </template>
                        <template v-else>
                            {{ item[header.value] }}
                        </template>
                    </td>
                </tr>
            </template>
        </base-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
    READ_COMPETITION_CLASSIFICATIONS,
    READ_COMPETITION_GATES,
    READ_CLASSIFICATION,
    READ_CLASSIFICATION_PLAYERS,
    READ_CLASSIFICATION_CHECKPOINTS,
    READ_CLASSIFICATION_RESULTS_POSITIONS,
    READ_CLASSIFICATION_RESULTS_POSITIONS_OPEN,
    READ_CLASSIFICATION_RESULTS_SPLIT,
    READ_CLASSIFICATION_RESULTS_LAP,
    READ_DECLARE_RESULT_CONFIG,
} from '@/store/actions.type';
import {
    SET_ZEN_MODE,
} from '@/store/mutations.type';
import actions from '@/mixins/actions';
import { insert } from '@/utils/array';
import moment from 'moment';
import { DNS_TIME, DNF_TIME, DSQ_TIME } from '@/config';
import { sortBy } from 'lodash';

export default {
  name: 'PanelCompetitionResultsLive',
  mixins: [actions],
  props: {
    id: {
      type: Number,
      required: true,
    },
    classificationId: {
      type: Number,
      required: true,
    },
  },
    data() {
    return {
      ready: false,
      isLargeScreen: false,
      headersDefault: [
          { text: 'Open', value: 'position' },
          { text: 'Nr', value: 'number' },
          { text: 'Nazwisko i imię', value: 'name' },
          { text: 'Klub', value: 'clubname' },
          { text: 'Kat', value: 'category' },
          { text: 'Miejsce', value: 'categoryPosition' },
      ],
      positions: [],
      catPositions: [],
      splitTime: {},
      lapTime: {},
      isSplitTime: true,
      remoteConfig: {},
      componentKey: 0,
      items: [],
      polling: null
    };
  },
  computed: {
    ...mapGetters([
      'say',
      'isZenMode',
      'competitions',
      'competitionsSettings',
      'classifications',
      'classificationsBy',
      'categoriesBy',
      'checkpointsBy',
      'playersBy',
      'results',
      'gatesBy',
    ]),
    gates() {
      return this.gatesBy(this.checkpoints);
    },
    classification() {
      return this.classifications[this.classificationId];
    },
    competitionSetting() {
      return this.competitionsSettings[this.id];
    },  
    competition() {
      return this.competitions[this.id];
    },
    categories() {
      return this.categoriesBy(this.classification);
    },    
    ageCategories() {
      return this.categories.filter((category) => !category.special);
    },
    specialCategories() {
      return this.categories.filter((category) => category.special);
    },
    checkpoints() {
        const numberAsc = (a, b) => a.number - b.number;

        return this.checkpointsBy(this.classification).sort(numberAsc);
    },
    checkpointsHeaders() {
        if (!this.competition.isRemote) {
            const notStart = (checkpoint) => checkpoint.number > 0;

            return this.checkpoints.filter(notStart);
        }
        return [];
    },
    headers() {
        const checkpointsHeaders = this.checkpointsHeaders.map(
            (checkpoint) => ({
                text: checkpoint.name,
                value: `checkpoint_${checkpoint.id}`,
            }),
        );
        if (!this.competition.isRemote) {
            const headersDefaultWithoutTimeFiled = this.headersDefault.filter(
                (header) => header.text !== 'Czas',
            );

            return insert(
                headersDefaultWithoutTimeFiled,
                6,
                checkpointsHeaders,
            );
        }
        if (!this.remoteConfig.isTime) {
            const timeHeader = this.headersDefault.find(
                (header) => header.text === 'Czas',
            );
            timeHeader.value = 'distance';
            timeHeader.text = 'Dystans';
            const isDeclaredTimeHeader = this.headersDefault.find(
                (header) => header.value === 'isDeclaredTime',
            );
            isDeclaredTimeHeader.value = 'isDeclaredDistance';
        }

        return insert(this.headersDefault, 6, checkpointsHeaders);
    },
    players() {
      return this.playersBy(this.classification);
    },
    categoryItems() {
      return sortBy(this.items, (item) => item[this.headers[0].value]);
    },     
  },
  beforeDestroy () {
    clearInterval(this.polling) 
  },
  async created() {
    await this.READ_COMPETITION_CLASSIFICATIONS(this.competition.id);
    this.bootstrap();
  },
  methods: {
    ...mapMutations([SET_ZEN_MODE]),
    ...mapActions([
      READ_COMPETITION_CLASSIFICATIONS,
      READ_COMPETITION_GATES,
      READ_CLASSIFICATION,
      READ_CLASSIFICATION_PLAYERS,
      READ_CLASSIFICATION_CHECKPOINTS,
      READ_CLASSIFICATION_RESULTS_POSITIONS,
      READ_CLASSIFICATION_RESULTS_POSITIONS_OPEN,
      READ_CLASSIFICATION_RESULTS_LAP,
      READ_CLASSIFICATION_RESULTS_SPLIT,
      READ_DECLARE_RESULT_CONFIG,
    ]),
    async bootstrap() {
      this.ready = false
        const [{ open: positions }, splitTime, catPositions] = await Promise.all([
            this.READ_CLASSIFICATION_RESULTS_POSITIONS_OPEN(
                this.classificationId,
            ),
            this.READ_CLASSIFICATION_RESULTS_SPLIT(this.classificationId),
            this.READ_CLASSIFICATION_RESULTS_POSITIONS( this.classificationId),
            this.READ_CLASSIFICATION(this.classificationId),
            this.READ_CLASSIFICATION_PLAYERS(this.classificationId),
            this.READ_CLASSIFICATION_CHECKPOINTS(this.classificationId),
            this.READ_COMPETITION_GATES(this.competition.id),
        ]);
        if (this.competition.isRemote) {
            const remoteConfig = await this.READ_DECLARE_RESULT_CONFIG(this.id);
            this.remoteConfig = remoteConfig;
        }

        this.positions = positions;
        this.splitTime = splitTime;
        this.catPositions = catPositions
        this.makeTableItems()
        this.pollData()
        this.ready = true;
    },
    makeTableItems() {
      const filtered = this.players.filter((player) => {
        // if (this.selectedCategory) {
        //     return player.category.includes(this.selectedCategory.id);
        // }

        if (this.classificationId) {
            return (
                player.classification === this.classificationId
            );
        }

        return true;
      });

      const formatTime = (time) => {
          if (
              moment(time, '[P][Y][M]D[D][T]HH[H]mm[M]ss[S]SSS').format(
                  'D HH:mm:ss.SSS',
              ) === 'Invalid date'
          ) {
              return moment(
                  time,
                  '[P][Y][M]d[D][T]HH[H]mm[M]ss[S]SSS',
              ).format('d HH:mm:ss.SSS');
          }
          return moment(
              time,
              '[P][Y][M]D[D][T]HH[H]mm[M]ss[S]SSS',
          ).format('D HH:mm:ss.SSS');
      };
      const findPlayerPosition = (playerId) => { 
          if(this.catPositions && Object.keys(this.catPositions).length > 0) {
            return this.catPositions.players[playerId]
          }
          return null
        };

        let i = 0;
        const list = [];

        for (const player of filtered) {
          i += 1;
          const playerPosition = findPlayerPosition(player.id);
          const open = playerPosition ? playerPosition.open : null
          const category = this.categories.find(category => category.id === player.category[0]) ? this.categories.find(category => category.id === player.category[0]) : null
          const categoryPosition = playerPosition ? playerPosition[`category_age_${player.category[0]}`] : null
          const categoryShort = category && category.short ? category.short : '-'
          const item = {
              id: player.id,
              position: open,
              category: categoryShort,
              categoryPosition: categoryPosition,
              name: `${player.lastname} ${player.firstname}`,
              gender: player.gender,
              number: player.playerNumber,
              country: player.country,
              city: player.city,
              clubname: player.clubname,
              classificationId: player.classification,
              isDeclaredTime: player.timeType === 'declared',
              time: this.$options.filters.asStoper(player.time),
              distance: player.distance,
              isDeclaredDistance: player.distanceType === 'declared',
              dsq: false,
          }
          if (this.checkpointsHeaders.length) {
            let j = 0;
            for (const checkpoint of this.checkpointsHeaders) {
                let time = '';

                const checkpointSplitTime = moment.duration(
                    formatTime(this.splitTime[item.id]?.[j]?.time),
                );
                if (this.isSplitTime) {
                    time = this.$options.filters.asStoper(
                        checkpointSplitTime,
                    );
                } else {
                    const checkpointLapTime = moment.duration(
                        formatTime(this.lapTime[item.id]?.[j]?.time),
                    );
                    time = this.$options.filters.asStoper(
                        checkpointLapTime,
                    );
                }

                if (checkpoint.number !== 1) {
                    // Removing hundredths of a second except meta.
                    [time] = time.split('.');
                }

                item[`checkpoint_${checkpoint.id}`] = time;
                if (
                    [DNS_TIME, DNF_TIME, DSQ_TIME].includes(
                        checkpointSplitTime.valueOf(),
                    )
                ) {
                    // Remove position and change lap time on DNS/DNF/DSQ instead time.
                    item.position = '';
                    item.dsq = true;
                    item[
                        `checkpoint_${checkpoint.id}`
                    ] = this.$options.filters.asStoper(
                        checkpointSplitTime,
                    );
                }
                // eslint-disable-next-line no-plusplus
                j++;
            }
          }
          if(player.playerNumber !== 0 && !item.dsq) list.push(item);
        }

        this.items = list;
    },  
    pollData () {
      let oldNodesLength = document.querySelectorAll('.table-list__row:not(.hidden)').length
      this.polling = setInterval(() => {
        Promise.all([
            this.READ_CLASSIFICATION_RESULTS_POSITIONS_OPEN(
                this.classificationId,
            ),
            this.READ_CLASSIFICATION_RESULTS_SPLIT(this.classificationId),
            this.READ_CLASSIFICATION_RESULTS_POSITIONS( this.classificationId),
        ]).then((values) => {
            this.positions = values[0];
            this.splitTime = values[1];
            this.catPositions = values[2]
            this.makeTableItems()
            setTimeout(() => {
                let nodes = document.querySelectorAll('.table-list__row:not(.hidden)')
                let last = nodes[nodes.length -1]
                if(nodes.length > oldNodesLength) {
                  last.scrollIntoView({behavior: "smooth", block: "end"});
                  oldNodesLength = nodes.length
                }
            }, 300);
        });
      }, 10000)
	}  
  }
}
</script>

<style scoped>
tbody .table-list__row:nth-of-type(even) {
  background-color: #fff;
  box-shadow: 0 1px 0 0 #c7c7c7, inset 0 1px 0 0 #c7c7c7;
}
.container-result {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}
.table-list {
  color: #000;
}
.table-list__row.bg--dark > th {color: #fff}
.table-list__row.bg--dark > th {color: #fff}
.table-list__row.bg--dark > th.table-list__header--first-cell {background-color: var(--color-charcoal-grey);color: #fff;box-shadow: none}
.table-list__row.bg--dark > th.table-list__header--second-cell {background-color: var(--color-charcoal-grey);color: #fff;box-shadow: none;}
.table-list .table-list__header--first-cell, .table-list .table-list__header--second-cell {max-width: 100px;}
.table-list__row  {transform-origin: left; animation: anim .3s ease-in-out;}
.table-list__row.hidden {transform: scale(0);display: none;opacity: 0;transition: transform .3s ease-in-out, opacity .3s ease-in-out;}
@keyframes anim {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>