<template>
  <div style="overflow: hidden"> 
    <main v-if="!ready">
      <div class="d-flex flex-column mat24">
          <h2 class="text-center">Trwa wczytywanie listy startowej.</h2>
          <div class="mat16 lds-ellipsis lds-ellipsis--center">
              <div />
              <div />
              <div />
          </div>
      </div>
    </main>
    <div v-show="ready" class="stickyHeader" data-v-sticky-container>
      <div v-sticky="stickyOptions" ref="stickyHeader">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="panel-competition-list-toolbar" :class="{'small': isZenMode}">
                <base-button-group horizontal :border="false" shadow>
                  <base-button
                    btn9
                    :badge="
                      isZenMode
                        ? 'dsi dsi-collapse-wide'
                        : 'dsi dsi-expand-wide'
                    "
                    style="padding: 0;border-top-right-radius: 0;border-bottom-right-radius: 0;"
                    @click="fullScreenTrigger"
                  />
                  <base-button
                    v-if="!isZenMode"
                    btn9
                    badge="dsi dsi-cog"
                    style="padding: 0;border-top-left-radius: 0;border-bottom-left-radius: 0;"
                    @click="$refs.optionsModal.openModal()"
                  >
                  </base-button>
                </base-button-group>
                <div class="searchWrap" :class="{'big': isZenMode}">
                  <base-input-text-with-icon
                    v-model="search"
                    class="minimal"
                    label="Szukaj"
                    icon="fas fa-search"
                    badge="fas fa-times"
                    @icon-click="search = ''"
                  />
                </div>
                <div class="btn-wrap" v-if="!isZenMode">
                  <base-button
                    btn9
                    icon="dsi dsi-cloud-upload"
                    @click="openUploadModal"
                  >
                  Wgraj dane
                  </base-button>
                </div>
                <div class="btn-wrap" v-if="!isZenMode">
                  <base-button
                    btn9
                    icon="dsi dsi-download"
                    @click="openDownloadModal"
                  >
                  <span>Pobierz<span class="d-none d-md-inline-block" style="margin-left: 4px;">jako</span>...</span>
                  </base-button>
                </div>
                <!-- <div class="btn-wrap" v-if="!isZenMode">
                  <base-button
                    btn9
                    icon="dsi dsi-cloud-upload"
                    @click="legacyPanel"
                  >
                  Stara wersja
                  </base-button>
                </div> -->
                <div v-if="!isZenMode">
                  <base-button
                    btn9
                    icon="dsi dsi-sort-numeric-down"
                    @click="navigateTo('panel-competition-setting-numbers')"
                  >
                  Nadaj numery
                  </base-button>
                </div>
                <div class="btn-add-user">
                  <base-button
                    btn1
                    style="height: 40px;box-sizing: border-box;"
                    :class="{'big': isZenMode}"
                    @click="openUserModal"
                  >
                  Dodaj uczestnika
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-result">
        <base-table
            v-if="playerListItems && playerListItems.length > 0"
            class="table-list"
            :headers="headersDefault"
            :items.sync="playerListItems"
            :search="search"
            :sortColumnDefault="tablesort.column"
            :sortReverseDefault="tablesort.reverse"
            v-click-outside="onClickOutside"
            @update-sort="tableSortUpdate"
        >
            <template v-slot:header="{ sort }">
                <tr class="table-list__row bg--dark">
                    <th
                        v-for="(header, column) in headersDefault"
                        :key="column"
                        class="table-list__header"
                        :class="{
                            'table-list__header--first-cell': header.value === 'playerNumber',
                            'table-list__header--min-width': header.value === 'playerChip',
                            'table-list__header--pay': header.value === 'pay',
                        }"
                        @click="sort(column)"
                    >
                      {{ header.text }}
                    </th>
                </tr>
            </template>

            <template v-slot:body="{ displayedItems }">
                <PanelCompetitionListTableRow 
                  v-for="(item, index) in displayedItems"
                  :key="item.key"
                  :item.sync="item"
                  :headers-default="headersDefault" 
                  :competitionId="id"
                  :competitionName="$attrs.name"
                  :lp="index"
                  @openUserModal="openUserModal"
                  @openPayLinkModal="openPayLinkModal"
                  :index="item.id"
                  :openedIndex="openedIndex"
                  @change-accordion="(newOpen) => openedIndex = newOpen"
                  @update="updatePlayer"
                />
            </template>

            <template v-slot:no-results="{ search: term }">
                <p class="bg--gray pa16">
                    {{ say.no_search_results }}
                    <b>{{ term }}</b>
                </p>
            </template>
        </base-table>
      </div>      
    </div>
    <modal ref="optionsModal" id="optionsModal" bodyClass="bg">
      <template v-slot:header>Dane wyświetlane na liście startowej</template>
      <template v-slot:body>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 col-md-6">
              <h4>Informacje podstawowe</h4>
              <div class="checkboxWrap">
                <BaseInputCheckbox v-for="(header, index) in headers" :key="index" v-model="headers[index].checked" :checkbox-value="headers[index].checked" :name="`agreement_ids[${index}]`">{{ header.text }}</BaseInputCheckbox>
              </div>
            </div>
            <div class="col-12 col-md-6 pr-24">
              <div v-if="inputsAdditionalInformation && inputsAdditionalInformation.length > 0">
                <h4>Informacje dodatkowe</h4>
                <div class="checkboxWrap">
                  <BaseInputCheckbox v-for="(input, index) in inputsAdditionalInformation" :key="index+'add'" v-model="additionalHeaders" :checkbox-value="{ value: input.id, text: input.fNamePl }" :name="`input_ids_add[${index}]`">{{ input.fNamePl }}</BaseInputCheckbox>
                </div>
              </div>
              <div class="mab24">
                <h4>Informacje pomocnicze</h4>
                <p>Zaznaczone informacje pomocnicze pojawią się na liście startowej. Nie są widoczne dla uczestników</p>
                <div class="checkboxInput" v-for="(input, index) in inputsSupportInformation">
                  <BaseInputCheckbox v-model="input.checked" :key="index+'sup'" :checkbox-value="{ value: input.id, text: input.fNamePl }" :ref="`input_ids_sup_${index}`" :name="`input_ids_sup[${index}]`"></BaseInputCheckbox>
                  <base-input-text :key="index+'supi'" v-model="input.fNamePl" hint="Nazwa kolumny z informacją pomocniczą" />
                  <!-- <base-input-text :key="index+'supi'" v-model="input.fNamePl" hint="Nazwa kolumny z informacją pomocniczą" @input="callDebounceSupportsInputs({ value: input.id, text: input.fNamePl })" /> -->
                  <!-- <div v-if="input.id" class="inputRemove" @click="removePlayerInput(input.id)"><i class="far fa-trash" /></div> -->
                </div>
              </div>
              <div>
                <h4>Informacja dodatkowa dla uczestnika</h4>
                <p>Dodaj specjalną kolumnę na liście startowej, aby ponformować uczestników o sektorze, lub fali, w której startują. Informacje uzupełnij w edycji uczestnika na liście startowej.</p>
                <div class="checkboxInput">
                  <BaseInputCheckbox v-model="frontColumn.checked" name="frontColumnCheck" ></BaseInputCheckbox>
                  <base-input-text v-model="frontColumn.text" hint="Nazwa kolumny z informacją pomocniczą" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <base-button btn9 @click="$refs.optionsModal.closeModal()" class="btn-short">Anuluj</base-button>
        <base-button btn1 @click="saveDefaultHeaders" class="btn-long">Zapisz</base-button>
      </template>
    </modal>    
    <modal ref="uploadModal" id="uploadModal" bodyClass="h-auto"  @modal-close="defaultUploadModalData" disabled-backdrop>
      <template v-slot:header>Wgraj dane</template>
      <template v-slot:body>
        <template v-if="uploadDataContainer === 'list'">
          <div v-if="uploadListLoader" class="loader">
            <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <p>Sprawdzamy poprawność danych<br class="d-none d-md-block" /> i importujemy listę</p>
          </div>
          <div v-else-if="uploadSuccess" class="up-wrap">
            <img src="../assets/images/icons/checkmark-circle.svg" />
            <p class="text-lg"><b>Lista startowa została poprawnie zaimportowana</b></p>  
            <p>Znależliśmy {{ importListLength }} uczestników</p>
          </div>
          <div v-else-if="uploadError" class="up-wrap">
            <img src="../assets/images/icons/close-circle.svg" />
            <p class="text-lg"><b>Nie udało się zaimportować listy</b></p>  
            <p v-if="uploadErrorMsg">{{ uploadErrorMsg }}</p>
            <p v-else>Aby zaimportować listę, wybierz kolumny<br class="d-none d-md-block" />z <b>imieniem, nazwiskiem, datą urodzenia i płcią</b></p>
          </div>
          <template v-else-if="uploadListData.length > 0">
            <panel-upload-table :data="uploadListData" :key="uploadListDataKey" @change="(headers) => headersForUploadList = headers" :role="uploadDataContainer" />
          </template>
          <div v-show="isUploadListWrapOpen" class="up-wrap">
            <img src="../assets/images/icons/upload.svg" />
            <input
              ref="uploadFile"
              class="input-field__input-file"
              type="file"
              @change="uploadListInputFileHandler($event)"
            >          
            <base-button btn9 @click="$refs.uploadFile.click()">Wybierz plik CSV</base-button>
            <p>Sprawdź jak powinien wyglądać poprawnie<br class="d-none d-md-block" /> sformatowany plik z wynikami.</p>
            <p><a href="https://dostartu.pl/files/demo/demo_playerslist_import.csv">pobierz przykładowy plik</a></p>
          </div>          
        </template>
        <template v-else-if="uploadDataContainer === 'chip'">
          <div v-if="uploadListLoader" class="loader">
            <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <p>Sprawdzamy poprawność danych<br class="d-none d-md-block" /> i importujemy listę</p>
          </div>
          <div v-else-if="uploadSuccess" class="up-wrap">
            <img src="../assets/images/icons/checkmark-circle.svg" />
            <p class="text-lg"><b>Lista startowa została poprawnie zaimportowana</b></p>  
            <p>Znależliśmy {{ importListLength }} uczestników</p>
          </div>
          <div v-else-if="uploadError" class="up-wrap">
            <img src="../assets/images/icons/close-circle.svg" />
            <p class="text-lg"><b>Nie udało się zaimportować listy</b></p>  
            <p v-if="uploadErrorMsg">{{ uploadErrorMsg }}</p>
            <p v-else>Aby zaimportować listę, wybierz kolumny<br class="d-none d-md-block" />z <b>numerem startowym i numerem chip.</b></p>
          </div>
          <template v-else-if="uploadListData.length > 0">
            <panel-upload-table :data="uploadListData" :key="uploadListDataKey" @change="(headers) => headersForUploadList = headers" :headers-data="chipHeadersData" :role="uploadDataContainer" />
          </template>
          <div v-show="isUploadListWrapOpen" class="up-wrap">
            <img src="../assets/images/icons/upload.svg" />
            <input
              ref="uploadFile"
              class="input-field__input-file"
              type="file"
              @change="uploadListInputFileHandler($event)"
            >          
            <base-button btn9 @click="$refs.uploadFile.click()">Wybierz plik CSV</base-button>
            <p>Sprawdź jak powinien wyglądać poprawnie<br class="d-none d-md-block" /> sformatowany plik z wynikami.</p>
            <p><a href="https://dostartu.pl/files/demo/demo_playerslist_import.csv">pobierz przykładowy plik</a></p>
          </div>           
        </template>
        <template v-else>
          <div v-show="!isUploadListWrapOpen" class="dl-wrap">
            <div class="dl-item">
              <div>
                <h4>Wgraj listę startową</h4>
                <p>Wgraj plik CSV z kolumnami zawierającymi imię, nazwisko, datę urodzenia i płeć.</p>
              </div>
              <div>
                <base-button
                  btn9
                  @click="uploadWrapTrigger('list')"
                >
                  Wgraj listę startową
                </base-button>
              </div>
            </div>
            <div class="dl-item">
              <div>
                <h4>Wgraj numery chipów</h4>
                <p>Wgraj plik CSV z kolumnami zawierającymi numer startowy i numer chip.</p>
              </div>
              <div>
                <base-button
                  btn9
                  @click="uploadWrapTrigger('chip')"
                >
                  Wgraj listę numerów chip
                </base-button>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-slot:footer>
        <template v-if="uploadSuccess">
          <div>&nbsp;</div>
          <base-button btn9 @click="refreshTable" class="btn-short">Wróć do listy startowej</base-button>
        </template>
        <template v-else-if="uploadError">
          <div>&nbsp;</div>
          <base-button btn9 @click="uploadError = false" class="btn-short">Wróć do wyboru kolumn</base-button>
        </template>
        <template v-else-if="!uploadListLoader">
          <template v-if="uploadDataContainer === 'list'">
            <base-button btn9 @click="$refs.uploadModal.closeModal()" class="btn-short">Anuluj</base-button>
            <div v-show="!uploadSuccess && uploadListData.length > 0" class="btn-wrap">
              <base-button btn9 @click="uploadListTrigger(true)" class="btn-short">Nadpisz listę</base-button>
              <base-button btn1 @click="uploadListTrigger" class="btn-long">Wgraj LISTĘ STARTOWĄ</base-button>
            </div>
          </template>
          <template v-else-if="uploadDataContainer === 'chip'">
            <base-button btn9 @click="$refs.uploadModal.closeModal()" class="btn-short">Anuluj</base-button>
            <div v-show="!uploadSuccess && uploadListData.length > 0" class="btn-wrap">
              <base-button btn9 @click="uploadChipTrigger(true)" class="btn-short">Nadpisz numery</base-button>
              <base-button btn1 @click="uploadChipTrigger" class="btn-long">Wgraj NUMERY CHIP</base-button>
            </div>
          </template>
          <template v-else>
            <base-button btn9 @click="$refs.uploadModal.closeModal()" class="btn-short">Anuluj</base-button>
          </template>
        </template>
      </template>
    </modal>    
    <modal ref="downloadModal" bodyClass="h-auto">
      <template v-slot:header>Pobierz listę startową jako...</template>
      <template v-slot:body>
        <div class="dl-info-wrap" :class="{ 'is-waiting': isWaiting }">
          <div class="form-row">
            <div class="form-col form-col-8">
              <h4>Kodowanie pobieranych plików</h4>
              <p>Jeśli na liście pojawiają się Imiona, Nazwiska i inne nazwy w obcych alfabetach, wybierz kodowanie UTF8</p>
            </div>
            <div class="form-col form-col-4">
              <h4 style="min-height: 8px;">&nbsp;</h4>
              <panel-input-select
                v-model="unicodeForDownload"
                :options="unicodeForSelect"
                :hide-selected="false"
              /> 
            </div>
          </div>
        </div>
        <div class="dl-wrap" :class="{ 'is-waiting': isWaiting }">
          <div class="dl-item">
            <div>
              <h4>Plik PDF</h4>
              <p>sformatowana do wydruku lista uczestników z numerami startowymi</p>
            </div>
            <div>
              <base-button
                btn9
                icon="dsi dsi-download"
                @click="downloadCompetition('pdf')"
              >
                Lista alfabetyczna
              </base-button>
              <base-button
                btn9
                icon="dsi dsi-download"
                @click="downloadCompetition('pdf-numbers')"
              >
                Lista wg. numerów
              </base-button>
            </div>
          </div>
          <div class="dl-item">
            <div>
              <h4>Plik XLS</h4>
              <p>format MS Excel</p>
            </div>
            <div>
              <base-button
                btn9
                icon="dsi dsi-download"
                @click="downloadCompetition('xls')"
              >
                Pełna lista
              </base-button>
              <base-button
                btn9
                icon="dsi dsi-download"
                @click="downloadCompetition('xls-headers')"
              >
                Tylko widoczne kolumny
              </base-button>
              <base-button
                btn9
                icon="dsi dsi-download"
                @click="downloadCompetition('xls-gep')"
              >
                Dla urządzeń Gepard
              </base-button>
            </div>
          </div>
          <div class="dl-item">
            <div>
              <h4>Plik CSV</h4>
              <p>obsługiwany przez większość arkuszy kalkulacyjnych</p>
            </div>
            <div>
              <base-button
                btn9
                icon="dsi dsi-download"
                @click="downloadCompetition('csv')"
              >
                Pełna lista
              </base-button>
              <base-button
                btn9
                icon="dsi dsi-download"
                @click="downloadCompetition('csv-del')"
              >
                Usunięci uczestnicy
              </base-button>
            </div>
          </div>
        </div>
        <div class="loading" v-if="isWaiting">
          <div class="lds-ellipsis lds-ellipsis--center">
            <div />
            <div />
            <div />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <base-button btn9 @click="$refs.downloadModal.closeModal()" class="btn-short">Anuluj</base-button>
      </template>
    </modal>    
    <modal ref="addNewUser" id="userModal" bodyClass="bg  h-auto visible" size="xl" @modal-close="defaultUserModalData">
      <template v-slot:header>
        <span v-if="form && form.id">Edytuj uczestnika</span>
        <span v-else>Dodaj uczestnika</span>
      </template>
      <template v-slot:body>
        <ValidationObserver v-slot="{ handleSubmit }" ref="newPlayerFormObserver">
          <form class="modal-body" :class="{ 'is-waiting': isWaiting }" @submit.prevent="handleSubmit(formSubmit)">
            <div class="form-row out-gutter">
              <div class="form-col form-col-8 border-right">
                <div class="form-row mab16sm">
                  <div class="form-col form-col-3">
                    <ValidationProvider name="firstname" rules="required" v-slot="{ errors }" class="scroll-margin">

                    <panel-input-text
                          v-model="form.firstname"
                          label="Imię"
                          class="mab16"
                          :error.sync="errors[0]"
                        />
                    </ValidationProvider>
                  </div>
                  <div class="form-col form-col-9">
                    <div class="form-row">
                      <div class="form-col form-col-6">
                        <ValidationProvider name="lastname" rules="required" v-slot="{ errors }" class="scroll-margin">
                        <panel-input-autocomplete
                          v-model="form.lastname"
                          :value="form.lastname"
                          label="Nazwisko"
                          optionLabel="lastname"
                          action="AUTOCOMPLETE_COMPETITION_PLAYER"
                          :action-id="competition.organizer"
                          class="mab16"
                          @input="changeLastname"
                          @select="selectPlayer($event)"
                          ref="formLastname"
                          :error.sync="errors[0]"
                        >
                        
                            <template v-slot:option-data="option">
                              {{ option.lastname + ' ' + option.firstname }}<br>
                              <small>{{ 'ur. ' + birthdayFormatDate(option.birthTime) }}</small>
                            </template>
                        </panel-input-autocomplete>
                      </ValidationProvider>
                        <!-- <span v-if="genderIssue" class="issue"><i class="fas fa-exclamation-triangle"></i></span> -->
                      </div>
                      <div class="form-col form-col-6">
                        <ValidationProvider name="gender" rules="required" v-slot="{ errors }" class="scroll-margin">
                          <panel-input-radio
                            v-model="form.gender"
                            name="formGender"
                            label="Płeć"
                            :options="[
                                { value: 'M', title: 'man' },
                                { value: 'K', title: 'woman' },
                            ]"
                            :value="form.gender"
                            class="mab16"
                            :error.sync="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-col form-col-3">
                    <ValidationProvider name="birthTime" rules="required|birthdayValidator" v-slot="{ errors }" class="scroll-margin">
                      <panel-input-date
                        v-model="form.birthTime"
                        :value="form.birthTime"
                        label="Data urodzenia"
                        placeholder="dd.mm.yyyy"
                        class="mab16"
                        :error.sync="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div class="form-col form-col-9">
                    <div class="form-row">
                      <div class="form-col form-col-6">
                        <ValidationProvider name="city" rules="required" v-slot="{ errors }" class="scroll-margin">
                          <panel-input-text
                            v-model="form.city"
                            label="Miejscowość"
                            class="mab16"
                            :error.sync="errors[0]"
                          />  
                        </ValidationProvider>               
                      </div>
                      <div class="form-col form-col-6">
                        <panel-input-text
                          v-model="form.country"
                          label="Kraj"
                          class="mab16"
                          :error.sync="errors.country"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-col form-col-3">
                    <panel-input-text
                      type="number"
                      v-model="form.phone"
                      label="Telefon"
                      class="mab16"
                      :error.sync="errors.phone"
                    />
                  </div>
                  <div class="form-col form-col-9">
                    <div class="form-row">
                      <div class="form-col form-col-6">
                        <panel-input-text
                          v-model="form.email"
                          label="Adres-email"
                          class="mab16"
                          :error.sync="errors.email"
                        />                
                      </div>
                      <div class="form-col form-col-6">
                        <panel-input-text
                          v-model="form.clubname"
                          label="Klub"
                          class="mab16"
                          :error.sync="errors.clubname"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-col form-col-3">
                    <panel-input-text
                      v-model="form.icePhone"
                      label="Telefon ICE"
                      class="mab8"
                      :error.sync="errors.icePhone"
                    />
                  </div>
                  <div class="form-col form-col-9">
                    <div class="form-row">
                      <div class="form-col form-col-6">
                        <panel-input-select
                          v-model="form.status"
                          :options="playerStatus"
                          :clearable="false"
                          class="mab16"
                          label="name"
                          trackBy="name"
                          selectLabel="Wybierz status"
                          :error.sync="errors.status"
                        />
                      </div>
                      <div class="form-col form-col-6">
                        <panel-input-switch
                          v-model="form.isAnonymous"
                          :value="form.isAnonymous"
                          name="formisAnonymous"
                          label="Anonimowy"
                          :options="[
                            { value: true, title: 'Tak' },
                            { value: false, title: 'Nie' },
                          ]"
                          class="mab16"
                          :error.sync="errors.isAnonymous"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hr__line">&nbsp;</div>
                <div class="form-row">
                  <div class="form-col form-col-3">
                    <panel-input-text
                      v-model="form.playerNumber"
                      label="Numer startowy"
                      class="mab16"
                      :error.sync="errors.playerNumber"
                    />
                  </div>
                  <div class="form-col form-col-9">
                    <div class="form-row">
                      <div class="form-col form-col-6">
                        <ValidationProvider name="classification" rules="required" v-slot="{ errors }">
                          <panel-input-select
                            v-model="placeClassification"
                            :options="classificationsForSelect"
                            selectLabel="Klasyfikacja"
                            :hide-selected="false"
                            class="mab16"
                            :error.sync="errors[0]"
                          />     
                        </ValidationProvider>          
                      </div>
                      <div class="form-col form-col-6">
                        <panel-input-select
                          v-if="specialCategories && specialCategories.length > 0"
                          v-model="form.category"
                          :options="specialCategories"
                          selectLabel="Kategoria specjalna"
                          multiple
                          searchable
                          :hide-selected="false"
                          class="mab16"
                          :error.sync="errors.category"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-col form-col-3">
                    <panel-input-text
                      v-model="form.playerGpsNumber"
                      label="Nr GPS"
                      class="mab24"
                      :error.sync="errors.playerGpsNumber"
                    />
                  </div>
                  <div class="form-col form-col-9">
                    <div class="form-row">
                      <div class="form-col form-col-6">
                        <panel-input-text
                          v-model="form.playerChip"
                          label="Numer chipa"
                          class="mab24"
                          :error.sync="errors.playerChip"
                        />              
                      </div>
                      <div class="form-col form-col-6">
                        <panel-input-switch
                          v-if="placeClassification && placeClassification.classificationPrices.length > 0"
                          v-model="form.pay"
                          :value="form.pay"
                          name="formStatus"
                          label="Opłata za udział"
                          :options="[
                            { value: true, title: 'Opłacony' },
                            { value: false, title: 'Nieopłacony' },
                          ]"
                          class="mab24"
                          :error.sync="errors.pay"
                        />
                        <div v-else-if="placeClassification && placeClassification.classificationPrices.length === 0">
                          <p class="mab8">Opłata za udział</p>  
                          <span>Klasyfikacja bezpłatna</span>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-md-none hr__line">&nbsp;</div>
              </div>
              <div class="form-col form-col-4 border-left">
                <div class="ps-wrap" style="display: none">
                  <div class="buttons-wrap">
                    <div class="btn" :class="{'is-active': buttonsTabs === 'additional-fields'}" id="additional-fields" @click="showCompetitionPanel('additional-fields')">
                      <span class="icon">
                        <img src="../assets/images/icons/add-to-list.svg" />
                      </span>
                      <span class="text">Pola dodatkowe</span>
                    </div>  
                    <div v-show="form.id && playerCycle.length > 0" class="btn" :class="{'is-active': buttonsTabs === 'competition-cycle'}" id="competition-cycle" @click="showCompetitionPanel('competition-cycle')">
                      <span class="icon">
                        <img src="../assets/images/icons/stopper.svg" />
                      </span>
                      <span class="text">Zawody w cyklu</span>
                    </div>  
                    <div v-show="form.id && playerShop.length > 0" class="btn" :class="{'is-active': buttonsTabs === 'orders'}" id="orders" @click="showCompetitionPanel('orders')">
                      <span class="icon">
                        <img src="../assets/images/icons/shopping-cart.svg" />
                      </span>
                      <span class="text">Zamówienia</span>
                    </div>  
                  </div>
                </div>
                <perfect-scrollbar ref="userModalScroll">
                  <div class="buttons-content-wrap" :class="{'is-active': buttonsTabs !== null }">
                    <div class="buttons-content" :class="{'is-active': buttonsTabs === 'additional-fields'}">
                      <div v-if="inputsAdditionalInformation && inputsAdditionalInformation.length > 0">
                        <panel-input-wrap v-for="(input, index) in inputsAdditionalInformation" :data="input" :key="input.id+'_'+index+'wrapAddUsr'" v-model="form.playerInputs[input.id].value" />
                      </div>
                      <div v-if="inputsSupportFormUser && inputsSupportFormUser.length > 0">
                        <panel-input-wrap v-for="(input, index) in inputsSupportFormUser" :data="input" :key="input.id+'_'+index+'wrapAddSuppUsr'" v-model="form.playerInputs[input.id].value" />
                      </div>
                      <div v-if="competition.frontColumn">
                        <panel-input-area
                          v-model="form.frontInfo"
                          :label="competition.frontColumn"
                          :rows="4"
                          class="mab24"
                        />                       
                      </div>
                      <panel-input-area
                        v-model="form.notes"
                        label="Notatka"
                        :rows="4"
                        class="mab24"
                      />                       
                    </div>
                    <div class="buttons-content" :class="{'is-active': buttonsTabs === 'competition-cycle'}">
                      <span class="text">Zawody w cyklu</span>
                    </div>
                    <div class="buttons-content" :class="{'is-active': buttonsTabs === 'orders'}">
                      <span class="text">Zamówienia</span>
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
              <div class="form-col form-col-" v-if="isMobile && form && form.id">
                <base-button btn9 @click="deleteUserFromList(form.id)" class="btn-short btn-outline-primary btn-remove-user" :class="{ 'is-waiting': isWaiting }">Usuń uczestnika</base-button>
              </div>
            </div>
            <button type="submit" class="d-none">for keyboard action</button>
            <div class="loading" v-if="isWaiting">
              <div class="lds-ellipsis lds-ellipsis--center">
                <div />
                <div />
                <div />
              </div>
            </div>
          </form>
        </ValidationObserver>
      </template>
      <template v-slot:footer>
        <template v-if="isMobile">
            <div class="btn-wrap">
              <base-button btn9 @click="$refs.addNewUser.closeModal()" class="btn-short" :class="{ 'is-waiting': isWaiting }">Anuluj</base-button>
              <base-button btn1 @click="formSubmit" class="btn-long" :class="{ 'is-waiting': isWaiting }">Zapisz</base-button>
            </div>
        </template>
        <template v-else>
          <div class="btn-wrap">
            <base-button btn9 v-if="form && form.id" @click="deleteUserFromList(form.id)" class="btn-short btn-outline-primary" :class="{ 'is-waiting': isWaiting }">Usuń uczestnika</base-button>
            <base-button btn9 @click="$refs.addNewUser.closeModal()" class="btn-short" :class="{ 'is-waiting': isWaiting }">Anuluj</base-button>
          </div> 
          <base-button btn1 @click="formSubmit" class="btn-long" :class="{ 'is-waiting': isWaiting }">Zapisz</base-button>
        </template>
      </template>
    </modal>  
    <modal ref="payLinkModal" id="payLinkModal" bodyClass="h-auto" @modal-close="playerPayLink = ''">
      <template v-slot:header>Link do płatności online</template>
      <template v-slot:body>
        <div class="downloadLink">
          <div class="downloadLinkWrap">
            <div class="downloadLinkInput">
              {{ playerPayLink }}
            </div> 
            <div @click="copyPayLinkTrigger" class="btn" :class="{'success' : playerPayCopied }">
              <template v-if="playerPayCopied">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.5 18.7929L21.1464 12.1464C21.3417 11.9512 21.6583 11.9512 21.8536 12.1464C22.0488 12.3417 22.0488 12.6583 21.8536 12.8536L14.8536 19.8536C14.6583 20.0488 14.3417 20.0488 14.1464 19.8536L11.1464 16.8536C10.9512 16.6583 10.9512 16.3417 11.1464 16.1464C11.3417 15.9512 11.6583 15.9512 11.8536 16.1464L14.5 18.7929Z" fill="white"/>
                </svg>
                Skopiowany
              </template>
              <template v-else>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.686 12.8601C11.8818 12.6653 11.8827 12.3488 11.6879 12.153C10.7787 11.2388 10.779 9.76187 11.6885 8.84801L13.7771 6.74933C14.74 5.78184 16.3001 5.763 17.286 6.70698C17.3177 6.73733 17.3485 6.76859 17.3784 6.80073C18.2373 7.72367 18.1854 9.16814 17.2624 10.027L14.9982 12.1341C14.7961 12.3222 14.7847 12.6386 14.9728 12.8407C15.1609 13.0429 15.4773 13.0543 15.6795 12.8661L17.9437 10.7591C19.2709 9.52395 19.3456 7.44673 18.1105 6.11948C18.0675 6.07327 18.0232 6.02832 17.9776 5.98467C16.5982 4.66396 14.4154 4.69031 13.0683 6.04392L10.9797 8.1426C9.682 9.44651 9.68166 11.5538 10.9789 12.8581C11.1736 13.0539 11.4902 13.0548 11.686 12.8601ZM12.1529 11.14C11.9571 11.3348 11.9562 11.6514 12.151 11.8472C13.0596 12.7607 13.0584 14.237 12.1483 15.149L9.98051 17.3215C9.07574 18.2282 7.60864 18.2343 6.69608 17.335C5.7869 16.4387 5.77643 14.9751 6.67269 14.0659L8.85614 11.851C9.05 11.6544 9.04774 11.3378 8.85108 11.1439C8.65443 10.9501 8.33786 10.9523 8.144 11.149L5.96054 13.3639C4.67656 14.6664 4.69156 16.7632 5.99438 18.0475C7.29835 19.3325 9.39522 19.3238 10.6884 18.0278L12.8562 15.8553C14.1553 14.5534 14.157 12.446 12.86 11.142C12.6653 10.9462 12.3487 10.9453 12.1529 11.14Z" fill="#fff"/>
                </svg>
                Skopiuj link
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <base-button btn9 @click="$refs.payLinkModal.closeModal()" class="btn-short">Zamknij</base-button>
      </template>
    </modal>          
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  CREATE_PLAYER,
  UPDATE_PLAYERS,
  DELETE_PLAYER,
  READ_PANEL_COMPETITION_PLAYERS,
  READ_PANEL_COMPETITION_PLAYERS_INPUTS,
  READ_COMPETITION_CLASSIFICATIONS,
  UPDATE_COMPETITION,
  CREATE_PANEL_COMPETITION_PLAYERS_INPUTS,
  UPDATE_PANEL_COMPETITION_PLAYERS_INPUTS,
  DELETE_PANEL_COMPETITION_PLAYERS_INPUTS,
  READ_PLAYER_CYCLE,
  READ_PLAYER_SHOP,
} from '@/store/actions.type';
import {
  SET_ZEN_MODE,
} from '@/store/mutations.type';
import moment from 'moment';
import Modal from '@/components/Modal';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import BaseInputCheckbox from '@/components/BaseInputCheckbox';
import PanelCompetitionListTableRow from '@/components/PanelCompetitionListTableRow';
import Cookies from 'js-cookie';
import { debounce } from 'lodash';
import vClickOutside from 'v-click-outside'
import PanelInputArea from '@/components/PanelInputArea';
import PanelInputWrap from '@/components/PanelInputWrap';
import PanelInputText from '@/components/PanelInputText';
import PanelInputRadio from '@/components/PanelInputRadio';
import PanelInputDate from '@/components/PanelInputDate';
import PanelInputSelect from '@/components/PanelInputSelect';
import PanelInputSwitch from '@/components/PanelInputSwitch';
import PanelInputAutocomplete from '@/components/PanelInputAutocomplete';
import api from '@/api';
import { downloadCsv, downloadXls } from '@/utils/files';
import ResizeSensor from "resize-sensor";
window.ResizeSensor = ResizeSensor;
import VueStickyDirective from '@renatodeleao/vue-sticky-directive';
import inputFiles from '@/mixins/input-files';
import PanelUploadTable from '@/components/PanelUploadTable';
import { isValid, isBefore, format } from "date-fns";
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'required_field'
})

extend('birthdayValidator', {
  message: 'required_field',
  validate(value) {
    const vDate = isValid(new Date(value)) ? new Date(value) : new Date(value.split('.')[2], value.split('.')[1], value.split('.')[0]);
    return isBefore(new Date('1900'), vDate) && isBefore(vDate, new Date())
  } 
});

export default {
  name: 'PanelCompetitionList',
  mixins: [inputFiles],
  directives: {
    clickOutside: vClickOutside.directive,
    "sticky": VueStickyDirective,
  },  
  components: {
    Modal,
    PerfectScrollbar,
    BaseInputCheckbox,
    PanelCompetitionListTableRow,
    PanelInputArea,
    PanelInputWrap,
    PanelInputText,
    PanelInputRadio,
    PanelInputDate,
    PanelInputSelect,
    PanelInputSwitch,
    PanelInputAutocomplete,
    PanelUploadTable,
    ValidationObserver,
    ValidationProvider
  },  
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headersForUploadList: [],
      uploadListDataKey: 0,
      uploadListLoader: false,
      isUploadListWrapOpen: false,
      uploadListData: [],
      importListLength: 0,
      uploadSuccess: false,
      uploadError: false,
      uploadErrorMsg: null,
      uploadDataContainer: null,
      chipHeadersData: [
        { name: "Wybierz", value: null },
        { name: "Numer startowy", value: "playerNumber" },
        { name: "Numer chip", value: "playerChip" }
      ],
      isWaiting: false,
      ready: false,
      loading: false,
      search: '',
      headersDefault: [
        { text: 'lp.', value: 'ordinalNumber' },
        { text: 'Nazwisko', value: 'lastname' },
        { text: 'Imię', value: 'firstname' },
        { text: 'Nr start.', value: 'playerNumber' },
        { text: 'Płeć', value: 'gender' },
        { text: 'Wiek', value: 'playerAge' },
        { text: 'Kategoria', value: 'ageCategory' },
        { text: 'Klasyfikacja', value: 'classification' },
        { text: 'Udział', value: 'status' },
        { text: 'Link do płatności', value: 'payLink' },
        { text: 'Opłata', value: 'pay' },        
      ],
      headers: [
        { text: 'lp.', value: 'ordinalNumber', checked: true },
        { text: 'Nazwisko', value: 'lastname', checked: true },
        { text: 'Imię', value: 'firstname', checked: true },
        { text: 'Nr start.', value: 'playerNumber', checked: true },
        { text: 'Chip', value: 'playerChip', checked: false },        
        { text: 'Gps', value: 'playerGpsNumber', checked: false },        
        { text: 'Płeć', value: 'gender', checked: true },
        { text: 'Data urodzenia', value: 'birthTime', checked: false },
        { text: 'Wiek', value: 'playerAge', checked: true },
        { text: 'Kategoria', value: 'ageCategory', checked: true },
        { text: 'Klasyfikacja', value: 'classification', checked: true },
        { text: 'Zapłacona suma', value: 'payment', checked: false },        
        { text: 'Data Opłaty', value: 'payedTime', checked: false },        
        { text: 'Numer telefonu', value: 'phone', checked: false },        
        { text: 'Numer tel. ICE', value: 'icePhone', checked: false },        
        { text: 'Państwo', value: 'country', checked: false },        
        { text: 'Miasto', value: 'city', checked: false },        
        { text: 'Adres e-mail', value: 'email', checked: false },        
        { text: 'Klub', value: 'clubname', checked: false },        
        { text: 'Data rejestracji', value: 'addedTime', checked: false },        
        { text: 'Adres', value: 'address', checked: false },        
        { text: 'Udział', value: 'status', checked: true },
        { text: 'Link do płatności', value: 'payLink' },
        { text: 'Opłata', value: 'pay', checked: true },        
        { text: 'Notatka', value: 'notes', checked: false },        
        { text: 'Anonimowy', value: 'isAnonymous', checked: false },        
      ],
      inputs: null,
      frontColumn: {
        competition: this.id,
        checked: false,
        text: this.$attrs.competition.frontColumn ? this.$attrs.competition.frontColumn : null,
        value: 'frontColumn'
      },
      additionalHeaders: [],
      supportHeaders: [],
      clickedId: null,
      openedIndex: -1,
      form: {
        firstname: '',
        lastname: '',
        gender: null,
        birthTime: null,
        city: '',
        country: 'POL',
        phone: '',
        email: '',
        clubname: '',
        playerNumber: '',
        playerChip: '',
        category: null,
        icePhone: '',
        playerGpsNumber: '',
        pay: false,
        playerInputs: {},
        frontInfo: '',
        notes: null,
        isAnonymous: false,
        classification: '',
        status: null,
      },
      placeClassification: null,
      playerCycle: [],
      playerShop: [],
      buttonsTabs: 'additional-fields',
      panelPlayers: null,
      errors: {},
      tableUpdateKey: 0,
      unicodeForDownload: {
        namePl: 'utf-8'
      },
      unicodeForSelect: [{
        namePl: 'utf-8'
      },{
        namePl: 'latin'
      }],
      genderIssue: false,
      tablesort: {
        column: '1',
        reverse: false,
      },
      playerPayLink: '',
      stickyOptions: {
        resizeSensor: true,
      },
      playerPayCopied: false,
      playerStatus: [
        {"value":0,"name":"Udział"},
        {"value":1,"name":"Brak udziału"},
        {"value":2,"name":"Nie Ukończył"},
        {"value":3,"name":"Dyskwalifikacja"}
      ],
	  playerListItems: []
    };
  },
  watch: {
    'form.firstname': debounce(function(newVal) {
		if (!this.form.id) {
			this.autoGender(newVal)
		}
      this.genderIssueTrigger()
    }, 500),
    'form.gender': debounce(function() {
      this.genderIssueTrigger()
    }, 500),
    'form.birthTime': debounce(function() {
      this.birthTimeIssueTrigger()
    }, 500),

  },
  async created() {
      await Promise.all([
        this.READ_PANEL_COMPETITION_PLAYERS(this.id),
        this.READ_PANEL_COMPETITION_PLAYERS_INPUTS(this.id),
        this.READ_COMPETITION_CLASSIFICATIONS(this.id),
      ]).then((values) => {
        this.panelPlayers = values[0]
        this.inputs = values[1]
		this.makePlayerList()
      });
	  if (this.competition && this.competition.cycle !== null) {
		this.headers.push({
			text: 'Opłata za numer', value: 'payNumber', checked: false 
		})
	  }
      if(Cookies.get('ds-default-panelcompetitionlist-headers')) {
        const newHeaders = JSON.parse(Cookies.get('ds-default-panelcompetitionlist-headers'))
        this.headersDefault = newHeaders
        for (const header of this.headers) {
          header.checked = false          
        }

		if (this.competition && this.competition.cycle === null && this.headersDefault.find(header => header.value === 'payNumber')) {
			this.headersDefault = this.headersDefault.filter(item => item.value !== "payNumber");
		}

        for (const newHeader of newHeaders) {
			if (this.headers.find(header => header.value === newHeader.value)) {
				this.headers.find(header => header.value === newHeader.value).checked = newHeader.checked
			}
        }
      }
      if(Cookies.get('ds-default-panelcompetitionlist-headers-additional')) {
        const additionalHeaders = JSON.parse(Cookies.get('ds-default-panelcompetitionlist-headers-additional'))
        for (const additionalHeader of additionalHeaders) {
          const id = this.inputsAdditionalInformation.find(header => header.id === additionalHeader.value)
          if(id) this.headersDefault = [ ...this.headersDefault, additionalHeader ]
          this.additionalHeaders.push(additionalHeader)
        }
      }
      if(Cookies.get('ds-default-panelcompetitionlist-headers-support')) {
        const supportHeaders = JSON.parse(Cookies.get('ds-default-panelcompetitionlist-headers-support'))
        for (const supportHeader of supportHeaders) {
          const id = this.inputsSupportInformation.find(header => header.id === supportHeader.value)
          if(id) {
            id['checked'] = true
            this.headersDefault = [ ...this.headersDefault, supportHeader ]
          }
          this.supportHeaders.push(supportHeader)
        }
      }
      if(Cookies.get('ds-default-panelcompetitionlist-headers-frontColumn')) {
        const localFrontColumn = JSON.parse(Cookies.get('ds-default-panelcompetitionlist-headers-frontColumn'))
        const frontColumn = localFrontColumn.find(item => item.competition === this.$attrs.competition.id) 
        if(frontColumn) {
          this.headersDefault = [ ...this.headersDefault, frontColumn ]
          this.frontColumn = frontColumn
        }
      }

      this.ready = true;
      this.$nextTick(() => {
        if(['lg', 'xl'].includes(this.$mq)) {
          this.$refs.stickyHeader._stickySidebar.destroy();
        }
      })
  },  
  computed: {
    ...mapGetters([
      'say',
      'isZenMode',
      'competitions',
      'classifications',
      'classificationsBy',
      'categories'
    ]),
    isMobile() {
      return ['sm', 'md'].includes(this.$mq);
    },      
    competition() {
      return this.competitions[this.id];
    },
    classificationsForSelect() {
      const classifications = this.classificationsBy(this.competition);
      const isDefault = classifications.filter((classification) => classification.isDefault)
      if(isDefault.length > 0) {
        this.placeClassification = isDefault[0]
      }
      return classifications
    },
    specialCategories() {
      const id = this.placeClassification ? this.placeClassification.classificationSetting : 0
      const obj = this.categories;
      const values = Object.values(obj);
      const result = values.filter((category) => category.special && category.classification === id);
      return result
    },  
    inputsAdditionalInformation() {
      const list = this.inputs ? this.inputs.filter(input => input.fType !== 'fc') : []
      return list
    },
    inputsSupportInformation() {
      const supports = [{
        "competition": this.id,
        "fNamePl": "",
        "fType": "fc",
        "isPublic": false,
        "checked": false,
      },{
        "competition": this.id,
        "fNamePl": "",
        "fType": "fc",
        "isPublic": false,
        "checked": false,
      }]
      const apiData = this.inputs.filter(input => input.fType === 'fc' && !input.isPublic)
      if(apiData.length > 0) {
        for (let input of apiData) {
          input['oldName'] = input.fNamePl
        }
      }
      const arr = apiData.length > 0 ? [...new Set([ ...apiData, ...supports ].map(a => JSON.stringify(a)))].map(a => JSON.parse(a)) : supports
      arr.length = 2
      return arr
    },
    inputsSupportFormUser() {
      const list = this.inputs ? this.inputs.filter(input => input.fType === 'fc' && !input.isPublic) : []
      return list
    }
  },
  methods: {
    ...mapActions([
      CREATE_PLAYER,
      UPDATE_PLAYERS,
      DELETE_PLAYER,
      READ_PANEL_COMPETITION_PLAYERS,
      READ_PANEL_COMPETITION_PLAYERS_INPUTS,
      READ_COMPETITION_CLASSIFICATIONS,
      UPDATE_COMPETITION,
      CREATE_PANEL_COMPETITION_PLAYERS_INPUTS,
      UPDATE_PANEL_COMPETITION_PLAYERS_INPUTS,
      DELETE_PANEL_COMPETITION_PLAYERS_INPUTS,
      READ_PLAYER_CYCLE,
      READ_PLAYER_SHOP,
    ]),    
    ...mapMutations([SET_ZEN_MODE]),
	makePlayerList() {
		let i = 0;
		const list = [];
		for (const id in this.panelPlayers) {
			i += 1;
			const player = this.panelPlayers[id]
			const item = this.playerItemDetails(player)

			list.push(item)
		}
	
		this.playerListItems = list;
	},
    navigateTo(name) {
      this.$router.push({
          name,
          params: this.competition,
          alias: true,
      });
    },
    tableSortUpdate(payload) {
      this.tablesort.column = payload.sortColumn.toString()
      this.tablesort.reverse = payload.sortReverse
    },
    fullScreenTrigger() {
      if(this.isZenMode) {
        this.$refs.stickyHeader._stickySidebar.destroy();
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
          this.$refs.stickyHeader.removeAttribute('style')
          this.$refs.stickyHeader.children[0].removeAttribute('style')
        }, 300)
      } else {
        this.$refs.stickyHeader._stickySidebar.initialize();
      }
      this.SET_ZEN_MODE(!this.isZenMode)
    },
    changeLastname(evt) {
      this.form.lastname = evt
    },
    updatePlayer(response) {
      const index = this.playerListItems.findIndex(player => player.id === response.id)
	  const guid = this.guid()
    	this.playerListItems[index] = this.playerItemDetails(response)
		this.playerListItems[index].key = guid
	  this.$set(this.playerListItems, index, { ...this.playerListItems[index], key: guid })
	//   this.tableKey()
    },
    tableKey: debounce(function () {
      this.tableUpdateKey += 1
      if(!this.isZenMode) {
        this.$refs.stickyHeader._stickySidebar.destroy();
        setTimeout(() => {
          this.$refs.stickyHeader.removeAttribute('style')
          this.$refs.stickyHeader.children[0].removeAttribute('style')
        }, 300)
      }
    }, 100),
    guid() {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      )
    },
    playerItemDetails(player) {
      const category = [];
      let status = null;
      for (let index = 0; index < player.category.length; index++) {
        const item = player.category[index];
        if (this.categories[item].special) {
          category.push(this.categories[item])
        }
      }
      if(player.status !== null) {
        status = this.playerStatus.filter(item => item.value === player.status)[0]
      }
      return  {
        id: player.id,
        playerNumber: player.playerNumber,
        lastname: player.lastname,
        firstname: player.firstname,
        gender: player.gender,
        birthTime: moment(player.birthTime).format('DD.MM.YYYY'),
        playerAge: player.playerAge,
        ageCategory: this.categories[player.ageCategory] ? this.categories[player.ageCategory].short : '',
        category,
        classification: this.classifications[player.classification] ? this.classifications[player.classification].short : '',
        placeClassification: this.classifications[player.classification] ? this.classifications[player.classification] : null,
        pay: player.pay,
        payNumber: player.payNumber,
        payedTime: player.payedTime ? moment(player.payedTime).format('DD.MM.YYYY') : null,
        payment: player.payment ? player.payment.amount + ' ' + player.payment.currency : '',
        phone: player.phone,
        icePhone: player.icePhone,
        country: player.country,
        city: player.city,
        email: player.email,
        clubname: player.clubname,
        playerChip: player.playerChip,
        addedTime: player.addedTime ? moment(player.addedTime).format('DD.MM.YYYY') : null,
        address: player.address,
        playerGpsNumber: player.playerGpsNumber,
        status,
        playerInputs: player.playerInputs,
        token: player.token,
        frontInfo: player.frontInfo,
        notes: player.notes,
        isAnonymous: player.isAnonymous,
        key: this.guid(),
      }
    },
    genderIssueTrigger() {
      if(this.form.gender === 'M' && this.form.firstname.slice(-1).toLowerCase() === 'a' || this.form.gender === 'K' && this.form.firstname.slice(-1).toLowerCase() !== 'a') {
        this.genderIssue = true
      } else {
        this.genderIssue = false
      }
    },
    birthTimeIssueTrigger() {
      if (this.form.birthTime) {
        var date = (moment(new Date(this.form.birthTime.split(".").reverse().join("-"))).toDate())
        var years = moment().diff(date, 'years');
  
        if (years > 99) {
          this.errors.birthTime = 'wrong_format_date'
        } else {
          this.errors.birthTime = null
        }
      }
    },
    autoGender(value) {
      if(value) {
        const endsWithA = value.slice(-1).toLowerCase() === 'a';
        if(endsWithA) {
          this.form.gender = 'K'
        } else {
          this.form.gender = 'M'
        }
      }
    },
    async updateCompetition() {
      const data = {
        ...this.$attrs.competition, 
        frontColumn: this.frontColumn.text
      }
      await this.UPDATE_COMPETITION(data);
    }, 
    async createPlayerInputs(input, checked = false) {
      const response = await this.CREATE_PANEL_COMPETITION_PLAYERS_INPUTS(input);
      const index = this.inputsSupportInformation.findIndex(item => item.fNamePl === response.fNamePl)
      this.inputsSupportInformation[index] = response
      this.inputsSupportInformation[index]['checked'] = checked
      if (checked) {
        this.supportHeaders.push({
          "competition": response.competition,
          "text": response.fNamePl,
          "value": response.id
        })
      }
    }, 
    async updatePlayerInputs(input, checked = null) {
      const data = {
        "competition": input.competition,
        "id": input.id,
        "fNamePl": input.fNamePl,
        "fType": input.fType,
        "isPublic": false
      }
      const response = await this.UPDATE_PANEL_COMPETITION_PLAYERS_INPUTS(data);
      const index = this.inputsSupportInformation.findIndex(item => item.fNamePl === response.fNamePl)
      const indexTableHeader = this.supportHeaders.findIndex(item => item.text === response.fNamePl)
      if (checked !== null) this.inputsSupportInformation[index]['checked'] = checked
      if (checked === false && indexTableHeader !== -1) {
        this.supportHeaders.splice(indexTableHeader, 1)
      } else if (checked === true) {
        this.supportHeaders.push({
          "competition": response.competition,
          "text": response.fNamePl,
          "value": response.id
        })
      }
      this.inputsSupportInformation[index]['oldName'] = response.fNamePl
    }, 
    async removePlayerInput(id) {
      await this.DELETE_PANEL_COMPETITION_PLAYERS_INPUTS(id);
      const response = await this.READ_PANEL_COMPETITION_PLAYERS_INPUTS(this.$attrs.competition.id)
      this.inputs = response
    }, 
    defaultUploadModalData() {
      this.uploadListLoader = false
      this.isUploadListWrapOpen = false
      this.uploadListData = []
      this.importListLength = 0
      this.uploadSuccess = false
      this.uploadError = false
      this.uploadErrorMsg = null
      this.uploadDataContainer = null
    },
    defaultUserModalData() {
      this.errors = {}
      this.placeClassification = null
      this.playerCycle = []
      this.playerShop = []
      this.playerIdToRemove = null
      this.form =  {
        firstname: '',
        lastname: '',
        gender: null,
        birthTime: null,
        city: '',
        country: 'POL',
        phone: '',
        email: '',
        clubname: '',
        playerNumber: '',
        playerChip: '',
        category: null,
        ageCategory: null,
        icePhone: '',
        playerGpsNumber: '',
        pay: false,
        playerInputs: {},
        frontInfo: '',
        notes: null,
        isAnonymous: false,
        classification: '',
        status: null,
      }
    },        
    async saveDefaultHeaders() {
      for (const input of this.inputsSupportInformation) {
        await this.changeSupportsInputs(input)
      }
      let newHeaders = this.headers.filter(header => header.checked)
      Cookies.set('ds-default-panelcompetitionlist-headers', JSON.stringify(newHeaders), { expires: 365 })

      if(this.additionalHeaders.length > 0) {
        for (const additionalHeader of this.additionalHeaders) {
          const id = this.inputsAdditionalInformation.find(header => header.id === additionalHeader.value)
          if(id) newHeaders = [ ...newHeaders, additionalHeader ]
        }
      } 
      Cookies.set('ds-default-panelcompetitionlist-headers-additional', JSON.stringify(this.additionalHeaders), { expires: 365 })
      if(this.supportHeaders.length > 0) {
        for (const supportHeader of this.supportHeaders) {
          const id = this.inputsSupportInformation.find(header => (header.id === supportHeader.value))
          if(id) newHeaders = [ ...newHeaders, supportHeader ]
        }        
      }
      Cookies.set('ds-default-panelcompetitionlist-headers-support', JSON.stringify(this.supportHeaders), { expires: 365 })
      let frontColumnArr = []
      if(Cookies.get('ds-default-panelcompetitionlist-headers-frontColumn')) {
        const localFrontColumn = JSON.parse(Cookies.get('ds-default-panelcompetitionlist-headers-frontColumn'))
        frontColumnArr.push(...localFrontColumn)
      }
      if(this.frontColumn.checked) {
        newHeaders = [ ...newHeaders, this.frontColumn ]
        frontColumnArr.push(this.frontColumn)
      } else {
        const newArr = frontColumnArr.filter(object => {
          return object.competition !== this.$attrs.competition.id;
        });
        frontColumnArr = newArr
      }
      const frontColumnArrResult = [...new Set(frontColumnArr.map(a => JSON.stringify(a)))].map(a => JSON.parse(a))
      Cookies.set('ds-default-panelcompetitionlist-headers-frontColumn', JSON.stringify(frontColumnArrResult), { expires: 365 })
      if(this.frontColumn.text !== null && this.frontColumn.text !== this.$attrs.competition.frontColumn) {
        this.updateCompetition()
      }
      //   if(input.fNamePl !== "" && !input.id) { 
      //     this.createPlayerInputs(input)
      //   } else if(input.id && input.fNamePl !== input.oldName) {
      //     this.updatePlayerInputs(input)
      //   }
      // }       

      this.headersDefault = newHeaders
      this.$refs.optionsModal.closeModal()
    },
    callDebounceSupportsInputs: debounce(function(obj) {
      this.changeSupportsInputs(obj);
    }, 500),    
    async changeSupportsInputs(obj) {
      const { id, fNamePl, checked } = obj
      if(fNamePl.length > 0) {
        if(id) {
          const input = this.inputsSupportInformation.find(item => item.id === id)
          input.fNamePl = fNamePl
          await this.updatePlayerInputs(input, checked)
        } else {
          const input = {
            "competition": this.id,
            "fNamePl": fNamePl,
            "fType": "fc",
            "isPublic": false
          }
          await this.createPlayerInputs(input, checked)
        }
      } else {
        if(id) {
          const inputIndex = this.supportHeaders.findIndex(item => item.value === id)
          if(inputIndex !== -1) this.supportHeaders.splice(inputIndex, 1)
          await this.removePlayerInput(id)

        }
      }
    },
    showCompetitionPanel(item) {
      // if(this.buttonsTabs === item) {
      //   this.buttonsTabs = null
      //   return
      // }
      this.buttonsTabs = item
    },    
    onClickOutside () {
      this.openedIndex = -1
    },
    openUploadModal() {
      this.$refs.uploadModal.openModal()
    },
    openDownloadModal() {
      this.$refs.downloadModal.openModal()
    },
    openPayLinkModal(player) {

      let route = this.$router.resolve({
        name: 'minisite-pay',
        params: {
            name: this.competition.name,
            id: this.competition.id,
            token: player.token,
        },
        alias: true,
      })
      this.playerPayLink = window.location.protocol + '//' + window.location.hostname + route.href
      this.$refs.payLinkModal.openModal();
    },
    copyPayLinkTrigger() {
      navigator.clipboard.writeText(this.playerPayLink).then(() => {
        this.playerPayCopied = true
        setTimeout(() => this.playerPayCopied = false, 3000)
      },() => {});
    },
    openUserModal(player) {
      for (const item of this.inputsAdditionalInformation) {
        this.form.playerInputs[item.id] = {
          "value": null
        }
      }
      for (const item of this.inputsSupportFormUser) {
        this.form.playerInputs[item.id] = {
          "value": null
        }
      }       
      if(player) {
        const { id, firstname, lastname, gender, birthTime, city, country, phone, email, clubname, playerChip, icePhone, playerGpsNumber, pay, placeClassification, playerInputs, frontInfo, isAnonymous, notes, category, ageCategory, status } = player

        this.placeClassification = placeClassification
        if(playerInputs.length > 0) {
          for (let input of playerInputs) {
            this.form.playerInputs[input.input] = {
              "id": input.id,
              "input": input.input,
              "value":  input.value
            }
          }
        }
        this.form =  {
          id,
          firstname,
          lastname,
          gender,
          birthTime,
          city,
          country,
          phone,
          email,
          clubname,
          playerNumber: player.playerNumber === 0 ? '' : player.playerNumber.toString(),
          playerChip,
          category,
          ageCategory,
          icePhone,
          playerGpsNumber,
          pay,
          playerInputs: this.form.playerInputs,
          frontInfo,
          notes,
          isAnonymous,
          classification: '',
          status,
        }
        this.READ_PLAYER_CYCLE(id)
        this.READ_PLAYER_SHOP(id)
      } 
      this.$refs.addNewUser.openModal();
      this.$nextTick(() => {
        if(['sm', 'md'].includes(this.$mq)) {
          this.$refs.userModalScroll.ps.destroy()
        }
        this.$refs.formLastname.$el.querySelector('.vs__search').focus()
      }) 
    },
    birthdayFormatDate(date) {
      const nDate = new Date(date)
      return format(nDate, 'dd.MM.yyyy')
    },
    selectPlayer(player) {
      if (this.form.id) this.playerIdToRemove = this.form.id;
      const { firstname, lastname, gender, birthTime, city, country, phone, email, clubname, icePhone, playerGpsNumber, category, ageCategory, status } = player
      const specialCategoriesIds = this.specialCategories.map(item => item.id)
      const categoriesIntersection = category.filter(x => specialCategoriesIds.includes(x))

      this.form =  {
        firstname,
        lastname,
        gender,
        birthTime,
        city,
        country,
        phone,
        email,
        clubname,
        playerNumber: '',
        category: categoriesIntersection,
        ageCategory,
        icePhone,
        playerGpsNumber,
        pay: false,
        playerInputs: {},
        frontInfo: '',
        notes: null,
        isAnonymous: false,
        classification: '',
        status,
      }
      for (const item of this.inputsAdditionalInformation) {
        this.form.playerInputs[item.id] = {
          "value": ''
        }
      }
      for (const item of this.inputsSupportFormUser) {
        this.form.playerInputs[item.id] = {
          "value": ''
        }
      }       
    },
    async formSubmit() {
      this.$refs.newPlayerFormObserver.validate().then(success => {
        if (!success) {
          for (const key of Object.keys(this.$refs.newPlayerFormObserver.fields)) {
            if (this.$refs.newPlayerFormObserver.fields[key].invalid) {
              const y = this.$refs.newPlayerFormObserver.refs[key].$el.getBoundingClientRect().top;
              this.$refs.newPlayerFormObserver.refs[key].$el.scrollIntoView({
                top: y,
                behavior: 'smooth',
              });
              return;
            }
          }
        }
        this.addNewPlayer()
      });
    },
    async addNewPlayer() {
      this.isWaiting = true
      const form = { ...this.form }
      const playerInputs = this.form.playerInputs
      form.classification = this.placeClassification ? this.placeClassification.id : ''
      form.playerInputs = []
      if (form.birthTime) form.birthTime = moment(new Date(form.birthTime.split(".").reverse().join("-"))).toDate()
      if (form.category && form.category.length > 0) {
        form.category = form.category.map(c => c.id)
      } else {
        form.category = []
      }
      if(form.status && Object.keys(form.status).length > 0) form.status = form.status.value
      for (let input in playerInputs) {
        if(playerInputs[input].value !== '' || playerInputs[input].value !== null) {
          let item = {
            "input": parseFloat(input),
            "value":  playerInputs[input].value
          }
          if(playerInputs[input].id) {
            item.id = playerInputs[input].id
          }
          form.playerInputs.push(item)
        }
      }
      
      if (this.playerIdToRemove) {
        await this.DELETE_PLAYER(this.playerIdToRemove)
        const index = this.playerListItems.findIndex(player => player.id === this.playerIdToRemove)
        this.playerListItems.splice(index, 1)
      }
      // console.log(form)
      try {
        if(this.form.id) {
          const response = await this.UPDATE_PLAYERS(form)
          const index = this.playerListItems.findIndex(player => player.id === response.id)
		  const guid = this.guid()
          this.playerListItems[index] = this.playerItemDetails(response)
			this.playerListItems[index].key = guid
	  		this.$set(this.playerListItems, index, { ...this.playerListItems[index], key: guid })
        } else {
          const response = await this.CREATE_PLAYER(form)
          this.playerListItems.push(this.playerItemDetails(response))
        }
        const players = await this.READ_PANEL_COMPETITION_PLAYERS(this.id)
        this.panelPlayers = players
        this.tableUpdateKey += 1
        this.$refs.addNewUser.closeModal()
      } catch (e) {
        this.errors = e;
        return;
      } finally {
        this.isWaiting = false
      }
    },
    async downloadCompetition(file) {
      this.isWaiting = true
      let headers = {
        Lang: this.unicodeForDownload.namePl
      }
      if(file === 'csv') {
        headers['Content-Type'] = 'text/csv'
        headers.Accept = 'text/csv'
      } else if (file === 'csv-del') {
        headers['Content-Type'] = 'text/csv+del'
        headers.Accept = 'text/csv+del'
      } else if (file === 'xls') {
        headers['Content-Type'] = 'text/xls'
        headers.Accept = 'text/xls'
      } else if (file === 'xls-gep') {
        headers['Content-Type'] = 'text/xls+gep'
        headers.Accept = 'text/xls+gep'
      } else if (file === 'xls-headers') {
        headers['Content-Type'] = 'text/xls'
        headers.Accept = 'text/xls'
        headers.Headers = this.headers.filter(header => header.checked).map(i => i.value).join(',')
      } else if (file === 'pdf') {
        headers['Content-Type'] = 'application/pdf'
        headers.Accept = 'application/pdf'
        headers.Headers = this.headers.filter(header => header.checked).map(i => i.value).join(',')
      } else if (file === 'pdf-numbers') {
        headers['Content-Type'] = 'application/pdf'
        headers.Accept = 'application/pdf'        
        headers.Headers = this.headers.filter(header => header.checked).map(i => i.value).join(',')
        headers.Sort = 'playerNumber'
      }
      try {
        const data = await api.get(`/panel/competitions/${this.competition.id}/playersInterface`, {
          responseType: 'blob',
          headers,
          data: {},
        });
        const fileTitle = this.competition.name.replace(/ /g, '_');
        const fileName = `${fileTitle}-${this.competition.id}-${file}`
        if(file === 'pdf' || file === 'pdf-numbers') {
          var blob = new Blob([data], {type: "application/pdf"});
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${fileName}.pdf`;
          link.click();
        }
        if(file === 'xls' || file === 'xls-gep' || file === 'xls-headers') {
          downloadXls(`${fileName}`, data)
        }      
        if(file === 'csv' || file === 'csv-del') {
          downloadCsv(`${fileName}`, data)
        }   
      } finally {
        this.isWaiting = false   
      }

    },
    async deleteUserFromList(id) {
      this.isWaiting = true
      await this.DELETE_PLAYER(id)
      const index = this.playerListItems.findIndex(player => player.id === id)
      this.playerListItems.splice(index, 1)
      this.tableUpdateKey += 1
      this.$refs.addNewUser.closeModal()
      this.isWaiting = false
    },
    legacyPanel() {
      let route = this.$router.resolve({
        name: 'panel-competition-list',
        params: this.competition,
        alias: true,
      })
      window.open(route.href, '_blank');
    },
    uploadWrapTrigger(upload) {
      this.uploadDataContainer = upload
      this.isUploadListWrapOpen = !this.isUploadListWrapOpen
    },
    async uploadListInputFileHandler(event) {
      this.isUploadListWrapOpen = false
      this.uploadListLoader = true
      const { files } = event.target;
      const [file] = await this.createBase64FileList(files);
      const response = await api.post(`/panel/competition/${this.competition.id}/upload/list`, file);
      this.uploadListData = response
      this.uploadListDataKey += 1
      this.uploadListLoader = false
    },
    async uploadListTrigger(overwrite = false) {
      if(this.headersForUploadList.includes('firstname' && 'lastname' && 'gender' && 'birthdate')) {
        this.uploadListLoader = true
        try {
          const data = {
            "headers": this.headersForUploadList,
            "overwrite": overwrite
          }
          const response = await api.post(`/panel/competition/${this.competition.id}/upload/list2`, data );
          this.importListLength = response
          this.uploadSuccess = true
        } catch(error) {
        if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          this.uploadError = true
        } finally {
          this.uploadListLoader = false
        }
      } else {
        this.uploadError = true
      }
    },
    async uploadChipTrigger(overwrite = false) {
      if(this.headersForUploadList.includes('playerNumber' && 'playerChip')) {
        this.uploadListLoader = true
        try {
          const data = {
            "headers": this.headersForUploadList,
            "overwrite": overwrite
          }
          const response = await api.post(`/panel/competition/${this.competition.id}/upload/additionalList`, data );
          this.importListLength = response
          this.uploadSuccess = true
        } catch(error) {
        if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          this.uploadError = true
        } finally {
          this.uploadListLoader = false
        }
      } else {
        this.uploadError = true
      }
    },
    async refreshTable() {
      this.$refs.uploadModal.closeModal()
      this.ready = false
      await Promise.all([
        this.READ_PANEL_COMPETITION_PLAYERS(this.id),
        this.READ_PANEL_COMPETITION_PLAYERS_INPUTS(this.id),
        this.READ_COMPETITION_CLASSIFICATIONS(this.id),
      ]).then((values) => {
        this.panelPlayers = values[0]
        this.inputs = values[1]
      });
      this.ready = true;
    }
  },
}
</script>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"></style>
<style scoped>
.panel-competition-list-toolbar {
  transition: 0.3s all;
  background: #455A64;
  border-radius: 4px;
  padding: 12px 0;
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}
.stickyHeader .is-affixed {
  z-index: 8;
}
.stickyHeader >>> .inner-wrapper-sticky {
  background-color: var(--color-charcoal-grey);
}
.panel-competition-list-toolbar.small {
  margin-top: 16px;
  margin-bottom: 16px;
}
.panel-competition-list-toolbar .input-field.minimal {
  min-height: 40px;
}
.panel-competition-list-toolbar .searchWrap.big {
  width: calc(100% - 372px);
}
.panel-competition-list-toolbar .btn.btn--1.big {
  width: 268px;
}
tbody .table-list__row:nth-of-type(even) {
  background-color: #fff;
  box-shadow: 0 1px 0 0 #c7c7c7, inset 0 1px 0 0 #c7c7c7;
}
.container-result {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}
.loading {background-color: rgba(247, 249, 249, 0);}
.is-waiting {opacity: 0.3; pointer-events: none;}
.table-list { color: #000;background-color: #fff;}
.table-list__row { cursor: pointer;}
.table-list__row.bg--dark { background-color: var(--color-charcoal-grey); }
.table-list__row.bg--dark > th {color: #fff}
.table-list__row.bg--dark > th {color: #fff}
.table-list__row.bg--dark > th.table-list__header--first-cell {background-color: var(--color-charcoal-grey);color: #fff;box-shadow: none;}
.table-list__row.bg--dark > th.table-list__header--second-cell {background-color: var(--color-charcoal-grey);color: #fff;box-shadow: none;}
.table-list__header--min-width {width: 160px;}
.yes {color: #50AE55;}
.no {color: #E62E53;}
.modal-body h4 {line-height: 24px;color: #455A64;margin-bottom: 8px;}
.modal-body p {line-height: 20px; color: #455A64;}
.checkboxInput {display: flex;margin-top: 16px;position:relative;}
.inputRemove {position: absolute;top: 10px;right: 8px;}
.checkboxInput .input-field {min-height: 40px;width: 100%;}
.checkboxWrap {display: flex; flex-wrap: wrap;}
.checkboxWrap > div {width: 50%;margin-bottom: 22px;}
.btn-wrap {display: flex;}
.btn-long {height: 40px;box-sizing: border-box;}
.btn-outline-primary {border: 1px solid #37474F;background-color: transparent;color:#37474F;}
.hr__line {position:relative;height: 32px;}
.hr__line:after {content: '';position: absolute; top: 15px;left: 0;background-color: rgba(84, 110, 122, 0.1);width: calc(100% + 32px); height: 1px;}
.pr-24 > div {padding-right: 24px;}
/* grind */
.form-row {margin-right: -16px; margin-left: -16px;display: flex;}
.form-row.out-gutter {padding-right: 16px; padding-left: 16px;}
.form-row.out-gutter > [class*='form-col-'] { padding-right: 0; padding-left: 0;}
.form-row:after, .form-row:before { display: table; content: " ";}
.form-row:after {clear: both;}
.form-col { position: relative;min-height: 1px;padding-right: 16px;padding-left: 16px;float: left;}
.form-row >>> p {color: #455A64 !important;}

@media (max-width: 991px) {
  .modal-body {padding: 24px 16px;}
  .form-row {flex-wrap: wrap;}
  .form-col {width: 100%;}
}
@media (min-width: 992px) {
  .modal-body {padding: 24px 4px 0 32px}
  .form-col-3 {width: 25%;}
  .form-col-4 {width: 33.33333333%;}
  .form-col-6 {width: 50%;}
  .form-col-8 {width: 66.66666667%;}
  .form-col-9 {width: 75%;}
  .border-right {padding-right: 32px !important;}
  .border-right:after {content: '';position: absolute; top: 0;right: 0;background-color: rgba(84, 110, 122, 0.1);width: 1px; height: 100%;}
  .border-left {padding-left: 32px !important;}
  .ps {height: 420px;padding-right: 32px;}
}

/* buttons-wrap */
.buttons-wrap {margin-bottom: 32px;display: flex;align-items: center;border-radius: 4px; border: solid 1px #37474F; overflow: hidden; box-sizing: border-box;}
.buttons-wrap .btn {display: flex;align-items: center;justify-content: center;flex-grow: 0;width: 40px;height: 40px;cursor: pointer;}
.buttons-wrap .btn.is-active {background-color: #DBE3E6;flex-grow: 1;width: auto;}
.buttons-wrap .btn.is-active .text {display: block;}
.buttons-wrap .btn:not(:first-child) {box-shadow: inset 1px 0px 0px #78909C;}
.buttons-wrap .btn .text {display: none; font-size: 14px;margin-left: 8px;color: #37474F;}
.buttons-wrap .btn .icon {position: relative;}
.buttons-content-wrap {display: none; position: relative;}
.buttons-content-wrap.is-active {display: block;}
.buttons-content-wrap .buttons-content {z-index: 3;position: relative; display: none;}
.buttons-content-wrap .buttons-content.is-active {display: block;}

/* download modal */ 

.dl-wrap {display: flex;justify-content: space-between;padding: 2rem;}
.dl-item {width: 240px;min-height: 220px;border: 1px solid #BDCBD1;border-radius: 4px;padding: 16px;display: flex;justify-content: space-between;flex-direction: column;box-sizing: border-box;}
.dl-item:not(:last-child) {margin-right: 24px;}
.dl-item >>> .btn + .btn {margin-top: 4px;}
.dl-item h4, .dl-info-wrap h4 {color: #455A64;margin-bottom: 8px;}
.dl-item p, .dl-info-wrap p {color: #455A64;}
.dl-info-wrap {background-color: #ECEFF1;padding: 2rem;}

/* upload modal */

.up-wrap {display: flex;flex-direction: column;align-items: center;justify-content: center;text-align: center;}
.up-wrap .btn {width: 215px;margin: 24px 0;}
.up-wrap p {margin-bottom: 16px;color: #455A64;line-height: 20px;}
.up-wrap p a {text-decoration: underline;}

.issue {color: orange;position: absolute;top: 35px;right: 25px;}

.downloadLink {background-color: #ECEFF1;padding: 16px;}
.downloadLinkWrap {position: relative;}
.downloadLinkWrap .btn {transition: 0.4s all;height: 40px;background: #546E7A;display: flex;align-items: center; justify-content: center; color: #fff;cursor: pointer;}
.downloadLinkWrap .btn.success {background: #50AE55;}
.downloadLinkWrap .btn svg {margin-right: 16px;}
.downloadLinkInput { box-sizing: border-box;background: #fff;box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);border-radius: 4px;padding: 0 12px;display: flex;align-items: center;color: #455A64;height: 40px; white-space: nowrap; overflow: hidden;} 

/* loader */ 

.lds-default { display: inline-block; position: relative; width: 96px; height: 96px; }
.lds-default div { position: absolute; width: 6px; height: 6px; background: #78909C; border-radius: 50%; animation: lds-default 1.2s linear infinite;}
.lds-default div:nth-child(1) {animation-delay: 0s;top: 37px;left: 66px;}
.lds-default div:nth-child(2) {animation-delay: -0.1s;top: 22px;left: 62px;}
.lds-default div:nth-child(3) {animation-delay: -0.2s;top: 11px;left: 52px;}
.lds-default div:nth-child(4) {animation-delay: -0.3s;top: 7px;left: 37px;}
.lds-default div:nth-child(5) {animation-delay: -0.4s;top: 11px;left: 22px;}
.lds-default div:nth-child(6) {animation-delay: -0.5s;top: 22px;left: 11px;}
.lds-default div:nth-child(7) {animation-delay: -0.6s;top: 37px;left: 7px;}
.lds-default div:nth-child(8) {animation-delay: -0.7s;top: 52px;left: 11px;}
.lds-default div:nth-child(9) {animation-delay: -0.8s;top: 62px;left: 22px;}
.lds-default div:nth-child(10) {animation-delay: -0.9s;top: 66px;left: 37px;}
.lds-default div:nth-child(11) {animation-delay: -1s;top: 62px;left: 52px;}
.lds-default div:nth-child(12) {animation-delay: -1.1s;top: 52px;left: 62px;}
@keyframes lds-default {  0%, 20%, 80%, 100% { transform: scale(1); } 50% { transform: scale(1.5); } }

#uploadModal >>> .loader {text-align: center;color: #455A64;}
.text-lg {font-size: 16px;}

@media (max-width: 991px) {
  .table-list__data--first-cell { z-index: 6; }
  .panel-competition-list-toolbar > div:not(:last-of-type) {margin-bottom: 12px}
  .panel-competition-list-toolbar:before {content: '';position: absolute;top: 0;left: -16px;width: calc(100% + 32px);height: 100%;background: #455A64;}
  .panel-competition-list-toolbar .btn-wrap {width: calc(50% - 4px);}
  .panel-competition-list-toolbar .btn-wrap > div { width: 100%;} 
  .panel-competition-list-toolbar .searchWrap, .panel-competition-list-toolbar .btn.btn--1, .panel-competition-list-toolbar .btn-add-user {width: 100%;}
  .panel-competition-list-toolbar .border-box {display: none;}
  .stickyHeader .is-affixed .panel-competition-list-toolbar {margin-top: 0;}
  .stickyHeader .is-affixed .btn-wrap {display: none;}
  #userModal >>> .modal__body {padding-bottom: 56px;}
  #userModal >>> .modal__footer {padding: 8px 16px 8px 16px; position: fixed; bottom: 0; left: 0; width: 100%; z-index: 10; box-sizing: border-box;}
  #userModal .btn-wrap, #userModal .btn-wrap .btn-long {width: 100%;}
  #optionsModal .btn-long {width: calc(100% - 96px);}
  #userModal .btn-wrap .btn-short, #optionsModal .btn-short {min-width: 88px;}
  #userModal .btn-remove-user {display: inline-flex;margin-top: 48px;margin-bottom: 24px;}
  #payLinkModal >>> .modal__footer {padding: 0 16px 8px 16px;}
  #payLinkModal >>> .modal__footer:before {display: none;}
  #payLinkModal >>> .modal__footer .btn {width: 100%;}
  .mab16sm {margin-bottom: 16px;}
  .btn-wrap > div:not(:last-child) {margin-right: 16px;}

}
@media (min-width: 992px) {
  .table-list__row.bg--dark > th.table-list__header--first-cell {min-width: 120px;}
  .table-list__header--pay { position: sticky; right: 0;background-color: var(--color-charcoal-grey);}
  .panel-competition-list-toolbar {margin-top: 40px;margin-bottom: 40px;padding: 24px 32px 32px; flex-wrap: nowrap;}
  .panel-competition-list-toolbar > div:not(:last-of-type) { margin-right: 32px; }
  .panel-competition-list-toolbar .searchWrap { min-width: 248px;}
  .panel-competition-list-toolbar .btn.btn--9:not(.btn--only-icon) { min-width: 148px;}
  .panel-competition-list-toolbar .btn.btn--1 {min-width: 180px;}
  .downloadLink {padding: 48px 32px;}
  .downloadLinkWrap .btn {min-width: 168px;position: absolute;top: 0;right: 0;}
  .d-md-none.hr__line {display: none;}
  .ps-wrap {padding-right: 32px;}
  .btn-wrap > div:not(:last-child) {margin-right: 32px;}
  .btn-short {min-width: 135px;}
  .btn-long {min-width: 236px;}
  #uploadModal >>> .modal__dialog {width: 968px;}
  #uploadModal >>> .dl-item {min-height: 168px;width: 50%;}
  #uploadModal >>> .dl-item .btn {display: inline-flex;padding: 0 24px;}
  .up-wrap {padding: 100px 0 72px;}
  #uploadModal >>> .loader {padding: 170px 0;}
  .d-md-block {display: block;}
}
.scroll-margin {scroll-margin-top: 8px;}
</style>
<style>
.ps__thumb-y {background-color: #455A64 !important;}
</style>