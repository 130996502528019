export const API_ADDRESS = process.env.VUE_APP_API_ADDRESS;
export const API_ADDRESS_NO_CACHE = process.env.VUE_APP_API_ADDRESS_NO_CACHE;
export const API_TIMEOUT = 30 * 1000;
export const IS_PRODUCTION_ENV = process.env.NODE_ENV === 'production';
export const { BASE_URL } = process.env;
export const FACEBOOK_PAGE = 'https://facebook.com/dostartu';
export const MAIL_ADDRESS = 'kontakt@dostartu.pl';
export const GOOGLE_API_KEY = 'AIzaSyBtSpqO3Y5-eMtAoaXRUZDFjeI-M-iOnUU';
export const GEOCODE_API_KEY = '53620c60-bceb-11eb-9f65-393d4501344f';
// export const GEOCODE_API_KEY = 'ca5c2190-0df4-11eb-95b2-9d3181697429';
export const HOTJAR_SITE_ID = '1358144';
export const GOOGLE_ANALYTICS_KEY = 'UA-47267144-14';
export const FACEBOOK_APP_ID = '229753097630976';
export const GTM_KEY = 'GTM-N8R24ZG';
export const TIME_INTERVAL = 60 * 1000;
export const DNS_TIME = 7 * 24 * 3600 * 1000 + 0; // 7 days + 0 in milliseconds
export const DNF_TIME = 7 * 24 * 3600 * 1000 + 10; // 7 days + 10 in milliseconds
export const DSQ_TIME = 7 * 24 * 3600 * 1000 + 20; // 7 days + 20 in milliseconds
export const SPORT_TYPES = [
    1,
    6,
    11,
    16,
    21,
    26,
    29,
    31,
    36,
    39,
    41,
    46,
    51,
    60,
    89,
    91,
    99,
];
export const STATUSES = {
    info: 1,
    open: 2,
    close: 3,
    live: 4,
    unofficial: 5,
    official: 6,
};
export const DEVICE_TYPES = {
    chronotrack: 'CHR',
    jkr: 'JKR',
    raceResult: 'RAR',
    gepard: 'GEP',
    stoper: 'STP',
    system: 'SYS',
};
export const TRACKPOINT_TYPES = {
    start: 'STA',
    meta: 'MET',
    checkpoint: 'CHK',
    buffet: 'BUF',
    return: 'RET',
    change: 'CHA',
    fans: 'FAN',
    office: 'OFF',
    parking: 'PAR',
};
export const COMPETITION_TYPES = {
    running: 1,
    mountain_running: 6,
    ski_running: 11,
    ocr: 16,
    nordic_walking: 21,
    duathlon: 26,
    swimrun: 29,
    cycling: 31,
    mtb: 36,
    adventure_trip: 39,
    rolls: 41,
    triathlon: 46,
    skiing: 51,
    swiming: 60,
    camp: 77,
    kids: 89,
    shooting: 91,
    other: 99,
};
