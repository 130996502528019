<template>
    <tr
      @mouseover="hover = true"
      @mouseleave="hover = false"
      class="table-list__row"
      :class="{
      'table-list__row--paid': playerPay || playerFree,
      'table-list__row--not__paid': !playerPay && !playerFree,
      'table-list__row--active': hover
      }"
  >
      <td
          v-for="(header, column) in headersDefault"
          :key="column"
          class="table-list__data"
          :class="{
            'table-list__data--first-cell': header.value === 'playerNumber',
            'table-list__data--min-width': header.value === 'playerChip',
            'table-list__data--max-width': header.value === 'playerGpsNumber',
            'table-list__data--lp': header.value === 'ordinalNumber',
            'table-list__data--pay': header.value === 'pay',
          }"
          @click="userDetails(item, header.value)"
      >
        <template v-if="header.value === 'ordinalNumber'">
          {{ lp + 1}}
        </template>
        <template v-else-if="header.value === 'playerNumber'">
          <form v-if="hover || index === openedIndex || isMobile" @submit.prevent="updatePlayer({id: item.id, playerNumber})">
            <div class="inputWrap">
              <input v-model="playerNumber">
              <button v-show="!isMobile" :class="{
                'accepted': state_number.accepted,
                'wrong': state_number.wrong
              }">
                <i v-if="state_number.submitted" class="fas fa-spinner"></i>
                <i v-else-if="state_number.accepted" class="fas fa-check"></i>
                <i v-else-if="state_number.wrong" class="fas fa-exclamation-triangle"></i>
                <i v-else class="fas fa-check-circle"></i>
              </button>
            </div>
          </form>
          <span v-else-if="!item['playerNumber']">-</span>
          <span v-else>{{ item['playerNumber'] }}</span>
        </template>
        <template v-else-if="header.value === 'payLink'">
          <span v-if="playerFree"></span>
          <div v-else class="btn"><img src="../assets/images/icons/link.svg" style="margin-right: 4px;" /> Kopiuj link</div>
        </template>
        <div class="flex-wrap" v-else-if="header.value === 'pay'">
          <div v-if="playerFree" class="btn" style="cursor: default">
            <span class="yes"><i class="fas fa-check-circle"></i> Bezpłatne</span>
          </div>
          <template v-else>
            <panel-input-switch-mini v-if="isMobile" v-model="playerPay" :value="playerPay" />
            <div v-else class="btn" @click="playerPay = !playerPay" :class="{ 'active': hover || index === openedIndex, 'yes': playerPay, 'no': !playerPay }">
              <span v-if="playerPay" class="yes"><i class="fas fa-check-circle"></i> Opłacony</span>
              <span v-else class="no"><i class="fas fa-times-circle"></i> Nieopłacony</span>
            </div>
          </template>
        </div>
        <div class="flex-wrap" v-else-if="header.value === 'payNumber'">
            <panel-input-switch-mini v-if="isMobile" v-model="playerpayNumber" :value="playerpayNumber" />
            <div v-else class="btn" @click="playerpayNumber = !playerpayNumber" :class="{ 'active': hover || index === openedIndex, 'yes': playerpayNumber, 'no': !playerpayNumber }">
              <span v-if="playerpayNumber" class="yes"><i class="fas fa-check-circle"></i> Opłacony</span>
              <span v-else class="no"><i class="fas fa-times-circle"></i> Nieopłacony</span>
            </div>
        </div>
        <div class="flex-wrap" v-else-if="header.value === 'isAnonymous'">
          <panel-input-switch-mini v-if="isMobile" v-model="playerisAnonymous" :value="playerisAnonymous" />
          <div v-else class="btn" @click="playerisAnonymous = !playerisAnonymous" :class="{ 'active': hover || index === openedIndex, 'yes': playerisAnonymous, 'no': !playerisAnonymous }">
            <span v-if="playerisAnonymous" class="yes"><i class="fas fa-check-circle"></i> Anonimowy</span>
            <span v-else class="no"><i class="fas fa-times-circle"></i> Nieanonimowy</span>
          </div>
        </div>
        <template v-else-if="header.value === 'playerChip'">
          <form v-if="hover || index === openedIndex || isMobile" @submit.prevent="updatePlayer({id: item.id, playerChip})">
            <div class="inputWrap">
              <input v-model="playerChip">
              <button v-show="!isMobile" :class="{
                'accepted': state_chip.accepted,
                'wrong': state_chip.wrong
              }">
                <i v-if="state_chip.submitted" class="fas fa-spinner"></i>
                <i v-else-if="state_chip.accepted" class="fas fa-check"></i>
                <i v-else-if="state_chip.wrong" class="fas fa-exclamation-triangle"></i>
                <i v-else class="fas fa-check-circle"></i>
              </button>
            </div>
          </form>          
          <span v-else class="ellipis">{{ item['playerChip'] }}</span>
        </template>
        <template v-else-if="header.value === 'playerGpsNumber'">
          <form v-if="hover || index === openedIndex || isMobile" @submit.prevent="updatePlayer({id: item.id, playerGpsNumber: Number(playerGpsNumber)})">
            <div class="inputWrap">
              <input v-model="playerGpsNumber" style="max-width: 68px" />
              <button v-show="!isMobile" :class="{
                'accepted': state_gps.accepted,
                'wrong': state_gps.wrong
              }">
                <i v-if="state_gps.submitted" class="fas fa-spinner"></i>
                <i v-else-if="state_gps.accepted" class="fas fa-check"></i>
                <i v-else-if="state_gps.wrong" class="fas fa-exclamation-triangle"></i>
                <i v-else class="fas fa-check-circle"></i>
              </button>
            </div>
          </form>          
          <span v-else class="ellipis">{{ item['playerGpsNumber'] }}</span>
        </template>
        <template v-else-if="typeof header.value === 'number'">
          <div v-html="playerInputs(header.value)"></div>
        </template>
        <template v-else-if="header.value === 'status'">
          <template v-if="playerStatus === 0 || playerStatus === 1">
            <panel-input-switch-mini v-if="isMobile" v-model="playerStatus" :value="playerStatus" />
            <div v-else class="btn" @click="playerStatus = !playerStatus" :class="{ 'active': hover || index === openedIndex, 'yes': playerStatus, 'no': !playerStatus }">
              <span v-if="playerStatus === 0" class="yes"><i class="fas fa-check-circle"></i> Udział</span>
              <span v-else-if="playerStatus === 1" class="no"><i class="fas fa-times-circle"></i> Brak udziału</span>
            </div>
          </template>
          <template v-else>-</template>
        </template>        
        <template v-else-if="header.value === 'classification' || header.value === 'ageCategory'">
          <span v-if="item[header.value] === '' || item[header.value] === null" class="no"><i class="fas fa-exclamation-triangle"></i></span>
          <span v-else>{{ item[header.value] }}</span>
        </template>
        <template v-else-if="header.value === 'gender'">
          <span v-if="genderIssue" class="issue"><i class="fas fa-exclamation-triangle"></i></span> {{ item[header.value] }} 
        </template>
        <template v-else-if="header.value === 'frontColumn'">
          {{ item['frontInfo'] }}
        </template>
        <template v-else>
			<template v-if="typeof item[header.value] === 'string'">{{ item[header.value].toLowerCase() }}</template>
			<template v-else>{{ item[header.value] }}</template>
        </template>
      </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex';
import {
  UPDATE_PLAYERS
} from '@/store/actions.type';
import PanelInputSwitchMini from '@/components/PanelInputSwitchMini';
import { debounce } from 'lodash';

export default {
  name: 'PanelCompetitionListTableRow',
  props: {
    item: {
      type: Object,
      required: true,
    },
    headersDefault: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    openedIndex: {
      type: [Number, Boolean],
      required: false,
      default: -1
    },
    competitionId: {
      type: Number,
      required: true,
    },
    competitionName: {
      type: String,
      required: true,
    },
    lp: {
      type: Number,
      required: true,
    },
  },
  components: {
    PanelInputSwitchMini,
  },
  data() {
    return {
      hover: false,
      state_number: {
        submitted: false,
        accepted: false,
        wrong: false,
      },
      state_chip: {
        submitted: false,
        accepted: false,
        wrong: false,
      },
      state_gps: {
        submitted: false,
        accepted: false,
        wrong: false,
      },
      playerFree: this.item.placeClassification.classificationPrices.length === 0,
      playerPay: this.item.pay,
      playerpayNumber: this.item.payNumber,
      playerisAnonymous: this.item.isAnonymous,
      playerNumber: this.item.playerNumber ? this.item.playerNumber : '',
      playerChip: this.item.playerChip ? this.item.playerChip : '',
      playerStatus: this.item.status ? this.item.status.value : null,
      playerGpsNumber: this.item.playerGpsNumber,
    };
  }, 
  watch: {
    'playerStatus': (function(newVal) {
      this.updatePlayer({id: this.item.id, status: newVal })
    }),
    'playerPay': (function(newVal) {
      this.updatePlayer({id: this.item.id, pay: newVal })
    }),
    'playerisAnonymous': (function(newVal) {
      this.updatePlayer({id: this.item.id, isAnonymous: newVal })
    }),
    'playerpayNumber': (function(newVal) {
      this.updatePlayer({id: this.item.id, payNumber: newVal })
    }),
  },   
  computed: {
    isMobile() {
      return ['sm', 'md'].includes(this.$mq);
    },    
    genderIssue() {
      if(this.item.gender === 'M' && this.item.firstname.slice(-1).toLowerCase() === 'a' || this.item.gender === 'K' && this.item.firstname.slice(-1).toLowerCase() !== 'a') {
        return true
      } 
      return false
    },
  },
  methods: {
    ...mapActions([
      UPDATE_PLAYERS,
    ]),
    guid() {
      return (new Date()).getTime() + Math.floor(Math.random() * 10000) + 1
    },
    playerInputs(header) {
      let value = this.item['playerInputs'].find(item => item.input === header) ? this.item['playerInputs'].find(item => item.input === header).value : '';
      if(value === '1') {
        value = `<div class="btn"><span class="yes"><i class="fas fa-check-circle"></i></span></div>`
      } else if(value === '0') {
        value = `<div class="btn"><span class="no"><i class="fas fa-times-circle"></i></span></div>`
      }
      return value
    },    
    userDetails(item, header) {
      const excludeModal = ['playerNumber', 'pay', 'playerChip', 'status', 'playerGpsNumber', 'isAnonymous', 'payNumber']
      if(excludeModal.includes(header)) {
        let value = this.index === this.openedIndex ? false : this.index;
        if(value) {
          this.$emit('change-accordion', value)
        }
      } else if(header === 'payLink' && !this.playerFree) {
        this.$emit('openPayLinkModal', item)
      } else {  
        this.$emit('openUserModal', item)
      }
    }, 
    async updatePlayer(payload) {
      if(payload.playerNumber || payload.playerNumber === '') this.state_number.submitted = true
      if(payload.playerChip) this.state_chip.submitted = true
      if(payload.playerGpsNumber) this.state_gps.submitted = true
      try {
        const response = await this.UPDATE_PLAYERS(payload)
        // if(payload.pay !== undefined) {this.playerPay = payload.pay}
        // if(payload.status !== undefined) {this.playerStatus = payload.status}
        // if(payload.playerNumber) this.state_number.accepted = true
        // if(payload.playerChip) this.state_chip.accepted = true
        // setTimeout(() => this.$emit('update', response), 2000)
        this.$emit('update', response)
        // this.item.key = this.guid()
      } catch (err) {
        if(payload.playerGpsNumber) this.state_gps.wrong = true
        if(payload.playerNumber) this.state_number.wrong = true
        if(payload.playerChip) this.state_chip.wrong = true
        if(payload.playerNumber) this.playerNumber = this.item.playerNumber ? this.item.playerNumber : ''
        if(payload.playerChip) this.playerChip = this.item.playerChip ? this.item.playerChip : ''
      } finally {
        setTimeout(() => {
          this.state_number.accepted = false
          this.state_chip.accepted = false
          this.state_number.wrong = false
          this.state_chip.wrong = false
          this.state_gps.accepted = false
          this.state_gps.wrong = false
        }, 2000)
      }
      
      this.state_number.submitted = false
      this.state_chip.submitted = false
      this.state_gps.submitted = false
    },
  }
}
</script>

<style scoped>
form {display: inline-block;}
.table-list__data >>> .yes {color: #00b60a;}
.table-list__data >>> .no {color: #ff0b3c;}
/* 
.table-list__data >>> .yes {color: #50AE55;} 
.table-list__data >>> .no {color: #E62E53;}
*/
.issue {color: orange;}
.table-list__data { padding: 0 0 0 12px;text-transform: capitalize;}
.table-list__data.table-list__data--first-cell {padding-left: 24px;}
.table-list__data--first-cell, .table-list__data--min-width {width: 100px;}
.table-list__data--max-width {width: 100px;}
.table-list__data--lp {width: 40px;}
.table-list__row {height: 40px;}
.table-list__row--paid, .table-list__row--paid .table-list__data--pay {background-color: #e1f9e3  !important;}
.table-list__row--not__paid, .table-list__row--not__paid .table-list__data--pay {background-color: #f9dee5 !important;}
.table-list__row.table-list__row--paid .table-list__data--first-cell { background-color: #e1f9e3; }
.table-list__row.table-list__row--not__paid .table-list__data--first-cell { background-color: #f9dee5; }
.table-list__row--active.table-list__row--paid {background-color: rgba(137, 216, 141, 0.6) !important;}
.table-list__row--active.table-list__row--not__paid {background-color: rgba(255,23,68,0.3) !important;}
.table-list__row--active.table-list__row--paid .table-list__data--first-cell {background-color: rgba(137, 216, 141, 0.1) !important;}
.table-list__row--active.table-list__row--not__paid .table-list__data--first-cell { background-color: rgba(255,23,68,0) !important;}
.table-list__row--active.table-list__row--paid .table-list__data--pay { background-color: rgba(137, 216, 141, 0.1) !important;}
.table-list__row--active.table-list__row--not__paid .table-list__data--pay { background-color: rgba(255,23,68,0) !important;}
.table-list__data >>> .btn {border: 1px solid transparent;border-radius: 4px;height: 32px; display: inline-flex; align-items: center; justify-content: center; padding: 0 8px;}
.table-list__data >>> .btn.active {border-color: #455A64;color: #455A64;}
.table-list__data >>> .btn.active.yes {border-color: #50AE55;background-color: #50AE55;}
.table-list__data >>> .btn.active.no {border-color: #E62E53;background-color: #E62E53;}
.table-list__data >>> .btn.active.yes > span, .table-list__data >>> .btn.active.no > span {color: #fff;}
.inputWrap {position: relative;height: 40px;background: #FFF;box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);border-radius: 4px;margin-left: -12px;}
.inputWrap input {height: 100%;box-sizing: border-box;border: none;padding-left: 12px;}
.inputWrap button {width: 40px;height: 40px;background-color: #546E7A;border: none;border-top-right-radius: 4px;border-bottom-right-radius: 4px;color: #fff;}
.inputWrap button.accepted {background: #50AE55;}
.inputWrap button.wrong {background: #E62E53;}
.ellipis {white-space: nowrap;width: 160px;text-overflow: ellipsis;display: inline-block;overflow: hidden;}
.table-list__row--active .btn.active.yes, .table-list__row--active .btn.active.no {border-color: #455A64;background-color: transparent;color: #455A64;}
.table-list__row--active .btn.active.yes > span, .table-list__row--active .btn.active.no > span {color: #455A64;}
.flex-wrap {display: flex;align-items: center;}
.flex-wrap > div:not(:last-child) {padding-right: 12px; margin-right: 12px; border-right: 1px solid rgba(0, 0, 0, 0.1);}
.table-list__data--pay {box-shadow: inset 1px 1px 0 0 #c7c7c7;}
@media (max-width: 991px) {
  .table-list__data--first-cell {padding-top: 4px;}
  .inputWrap {height: 32px;border-radius: 4px;overflow: hidden;}
  .inputWrap input {width: 48px;padding: 0 4px;}
}
@media (min-width: 992px) {
  .inputWrap input {width: calc(100% - 40px);}
  .table-list__data--pay {position: sticky; right: 0;}
}
</style>