import { render, staticRenderFns } from "./PanelCompetitionResult.vue?vue&type=template&id=21bcf60a&scoped=true&"
import script from "./PanelCompetitionResult.vue?vue&type=script&lang=js&"
export * from "./PanelCompetitionResult.vue?vue&type=script&lang=js&"
import style0 from "./PanelCompetitionResult.vue?vue&type=style&index=0&id=21bcf60a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21bcf60a",
  null
  
)

export default component.exports