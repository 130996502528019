<template>
    <div>
        <panel-competition-form-box>
            <div class="col-12 col-lg-8">
                <panel-input-text
                    v-model="form.name"
                    label="Nazwa wydarzenia"
                    :error.sync="errors.name"
                    hint="Wpisz nazwe"
                />
            </div>
            <div class="col-12 col-lg-8 mat16">
                <div class="row">
                    <div class="col-12 col-lg-7">
                        <p class="form-input__label">
                            Typ wydarzenia
                        </p>
                        <base-input-select
                            v-model="form.type"
                            :error.sync="errors.type"
                            is-panel
                            :searchable="false"
                            :clearable="false"
                            :options="competitionTypes"
                            :reduce="(item) => item.id"
                            without-error-message
                        />
                    </div>
                    <div class="col-12 col-lg-4">
                        <p
                            class="form-input__label form-input__label--competition-type"
                        >
                            Wydarzenie
                        </p>
                        <div class="d-flex">
                            <base-button
                                btn2
                                short
                                class="w100"
                                :class="{
                                    'form-input__competition-type--active': !form.isRemote,
                                }"
                                @click="form.isRemote = false"
                            >
                                Rzeczywiste
                            </base-button>
                            <base-button
                                btn2
                                short
                                class="mal4 w100"
                                :class="{
                                    'form-input__competition-type--active':
                                        form.isRemote,
                                }"
                                @click="form.isRemote = true"
                            >
                                Wirtualne
                            </base-button>
                        </div>
                    </div>
                </div>
            </div>
        </panel-competition-form-box>
        <panel-competition-form-box
            class="mat24"
            :align-center="!form.isRemote"
        >
            <div class="col-12 col-lg-8">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <p class="form-input__label">
                            Data i godzina zamknięcia zapisów online
                        </p>
                    </div>
                    <div class="col-8 col-lg-6">
                        <base-input-date
                            v-model="form.provisionTime"
                            :error.sync="errors.provisionTime"
                            is-short
                            class="form-input__date"
                            hint="Wybierz"
                            without-error-message
                        />
                    </div>
                    <div class="col-4 col-lg-3">
                        <div class="form-input__hour">
                            <base-input-mask
                                mask="00:00"
                                placeholder="GG:MM"
                                :value="provisionTimeHour"
                                @complete="changeProvisionTime"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <!-- Remote competition. -->
                    <div
                        v-if="form.isRemote || form.type === types.camp"
                        class="row justify-content-start"
                    >
                        <div
                            class="col-12 form-input__label form-input__label--duration"
                        >
                            Czas trwania wydarzenia
                        </div>
                        <div class="col-12">
                            <p class="form-input__label">
                                Data rozpoczęcia
                            </p>
                        </div>
                        <div class="col-8 col-lg-6">
                            <base-input-date
                                v-model="form.preStartedTime"
                                is-short
                                :error.sync="errors.preStartedTime"
                                class="form-input__date"
                                hint="Wybierz"
                                without-error-message
                            />
                        </div>
                        <div class="col-4 col-lg-3">
                            <div class="form-input__hour">
                                <base-input-mask
                                    mask="00:00"
                                    placeholder="GG:MM"
                                    :value="preStartedTimeHour"
                                    @complete="changePreStartedTime"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <p
                                class="form-input__label form-input__label--send-results"
                            >
                                Data zakończenia
                            </p>
                        </div>
                        <div class="col-8 col-lg-6 mat16">
                            <base-input-date
                                v-model="form.startedTime"
                                is-short
                                :error.sync="errors.startedTime"
                                class="form-input__date"
                                hint="Wybierz"
                                without-error-message
                            />
                        </div>
                        <div class="col-4 col-lg-3 mat16">
                            <div class="form-input__hour">
                                <base-input-mask
                                    mask="00:00"
                                    placeholder="GG:MM"
                                    :value="startedTimeHour"
                                    @complete="changeStartedTime"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        v-else
                        class="row mat16 justify-content-start"
                    >
                        <div class="col-12">
                            <p class="form-input__label">
                                Data i godzina wydarzenia
                            </p>
                        </div>
                        <div class="col-8 col-lg-6">
                            <base-input-date
                                v-model="form.startedTime"
                                is-short
                                :error.sync="errors.startedTime"
                                class="form-input__date"
                                hint="Wybierz"
                                without-error-message
                            />
                        </div>
                        <div class="col-4 col-lg-3">
                            <div class="form-input__hour">
                                <base-input-mask
                                    mask="00:00"
                                    placeholder="GG:MM"
                                    :value="startedTimeHour"
                                    @complete="changeStartedTime"
                                />
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <p class="form-input__hint">
                    Data i godzina zamknięcia zapisów online powoduje
                    automatyczne zablokowanie możliwości zapisu w wydarzeniu.
                    Rejestrację w każdym momencie można zamknąć/otworzyć
                    ręcznie.
                    <br><br>
                </p>
                <p
                    v-if="form.isRemote"
                    class="form-input__hint"
                >
                    Czas trwania wydarzenia to przedział czasu, w którym uczestnicy
                    mają możliwość zgłoszenia swojego wyniku.
                </p>
            </div>
        </panel-competition-form-box>
        <panel-competition-form-box
            class="mat16"
            align-end
        >
            <div class="col-12 col-lg-8">
                <div class="row">
                    <div class="col-12 col-lg-5">
                        <panel-input-text
                            v-model="form.contactPhone"
                            label="Nr telefonu organizatora"
                            :error.sync="errors.phone"
                        />
                    </div>
                    <div class="col-12 col-lg-7">
                        <panel-input-text
                            v-model="form.contactEmail"
                            class="form-input__email"
                            label="Adres e-mail organizatora"
                            :error.sync="errors.email"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <p class="form-input__hint">
                    Możesz wyedytować dane kontaktowe organizatora dla tych
                    wydarzenia.
                </p>
            </div>
        </panel-competition-form-box>
        <panel-competition-form-box class="mat16">
            <div class="col-12">
                <p
                    ref="location"
                    class="form-input__label"
                >
                    Lokalizacja
                </p>
            </div>
            <div class="col-12 col-lg-8">
                <base-input-autocomplete
                    v-model="locationName"
                    :error.sync="errors.location"
                    hint="wpisz lokalizację"
                    class="form-input__location"
                    :class="{ mab16: isCoordsCompetition }"
                    action="AUTOCOMPLETE_LOCATION"
                    without-error-message
                    clearable
                    @input="cityWithoutSelect"
                    @select="selectCity"
                    @search-blur="onSearchBlur"
                />
            </div>
            <div class="col-lg-4" />
            <transition name="fade-map">
                <div
                    v-if="isCoordsCompetition"
                    class="col-12 form-map"
                >
                    <div class="row">
                        <div
                            key="map"
                            class="col-12 col-lg-8"
                        >
                            <base-map
                                class="form-map__map"
                                :lat="form.locationLat"
                                :lng="form.locationLng"
                                :zoom="10"
                                :fullscreenable="false"
                            >
                                <l-marker
                                    :lat-lng="[
                                        form.locationLat,
                                        form.locationLng,
                                    ]"
                                    :draggable="true"
                                    @update:lat-lng="updateCoords"
                                >
                                    <l-icon :icon-anchor="[12, 32]">
                                        <div class="form-map__marker">
                                            <img
                                                src="@/assets/images/marker-ds.svg"
                                            >
                                        </div>
                                    </l-icon>
                                </l-marker>
                            </base-map>
                        </div>
                        <div
                            v-if="isCoordsCompetition"
                            class="col-12 col-lg-4"
                        >
                            <div class="form-input__hint">
                                Przesuń marker, aby ustawić dokładną
                                lokalizację.
                                <p v-if="!isEditPage">
                                    Ustawienie trasy i punktów można później
                                    dodać w zakladce Narzędzia.
                                </p>
                                <div
                                    v-else
                                    class="none-mobile"
                                >
                                    <p>
                                        Dokładną trasę i punkty możesz ustawić:
                                    </p>
                                    <base-button
                                        class="mat16"
                                        btn9
                                        badge="fas fa-map"
                                        @click="
                                            $router.push({
                                                name: 'panel-competition-map',
                                                params: form,
                                                alias: true,
                                            })
                                        "
                                    >
                                        Trasa i punkty
                                    </base-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </panel-competition-form-box>
        <panel-competition-form-box class="mat16">
            <div class="col-12">
                <p class="form-input__label">
                    Opis wydarzenia
                </p>
            </div>
            <div class="col-12 col-lg-8">
                <base-input-area
                    v-model="form.descriptionPl"
                    hint="Dodaj opis wydarzenia"
                    :error.sync="errors.descriptionPl"
                    :rows="10"
                />
            </div>
            <div class="col-12 col-lg-4">
                <p class="form-input__hint">
                    Dodaj opis wydarzenia. Przekaż uczestnikom
                    kluczowe informacje dotyczące wydarzenia. Opisz lokalizację,
                    nagrody, harmonogram.<br><br>
                    Pamiętaj, że treścią tego opisu możesz wpłynąć na decyzję
                    potencjalnych uczestników o wzięciu udziału w wydarzeniu.
                </p>
            </div>
        </panel-competition-form-box>
        <panel-competition-form-box class="mat16">
            <div class="col-12">
                <p class="form-input__label">
                    Ważny komunikat dla uczestników
                    <span
                        class="form-input__label--positive"
                    >(opcjonalnie)</span>
                </p>
            </div>
            <div class="col-12 col-lg-8">
                <base-input-area
                    v-model="form.remarksPl"
                    hint="Dodaj ważny komunikat"
                    :error.sync="errors.remarksPl"
                    :rows="10"
                />
            </div>
            <div class="col-12 col-lg-4">
                <p class="form-input__hint">
                    Informacje wpisane w tym miejscu zostaną pokazane w
                    wyróżnionym miejscu na stronie wydarzenia.<br><br>
                    Użyj tego miejsca aby poinformować uczestników np. o zmianie
                    godziny startu.<br><br>
                    Podstawowe informacje umieść w opisie wydarzenia.
                </p>
            </div>
        </panel-competition-form-box>
        <panel-competition-form-box class="mat16">
            <div class="col-12">
                <p class="form-input__label">
                    Oficjalna strona wydarzenia
                    <span
                        class="form-input__label--positive"
                    >(opcjonalnie)</span>
                </p>
            </div>
            <div class="col-12 col-lg-8">
                <panel-input-text
                    v-model="form.websitePl"
                    :error.sync="errors.websitePl"
                    hint="https://"
                />
            </div>
            <div class="col-12 col-lg-4">
                <p class="form-input__hint">
                    Podaj link do oficjalnej strony lub strony wydarzenia na
                    Facebooku.
                </p>
            </div>
        </panel-competition-form-box>
        <div class="col-lg-12 mat16 mab64">
            <div class="row justify-content-end">
                <div class="col-12 col-lg-4">
                    <base-button
                        btn1
                        short
                        @click="save()"
                    >
                        Zapisz
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PanelCompetitionFormBox from '@/components/PanelCompetitionFormBox';
import PanelInputText from '@/components/PanelInputText';
import { LMarker, LIcon } from 'vue2-leaflet';
import moment from 'moment';
import { COMPETITION_TYPES } from '@/config';
import form from '@/mixins/form';
import { debounce } from 'lodash';

export default {
    components: {
        PanelCompetitionFormBox,
        PanelInputText,
        LMarker,
        LIcon,
    },
    mixins: [form],
    data() {
        return {
            types: COMPETITION_TYPES,
            typesNames: {
                [COMPETITION_TYPES.running]: 'Biegi',
                [COMPETITION_TYPES.mountain_running]: 'Biegi górskie',
                [COMPETITION_TYPES.ski_running]: 'Biegi narciarskie',
                [COMPETITION_TYPES.ocr]: 'OCR',
                [COMPETITION_TYPES.nordic_walking]: 'Nordic Walking',
                [COMPETITION_TYPES.duathlon]: 'Duathlon',
                [COMPETITION_TYPES.swimrun]: 'Swimrun',
                [COMPETITION_TYPES.cycling]: 'Kolarstwo',
                [COMPETITION_TYPES.mtb]: 'MTB',
                [COMPETITION_TYPES.adventure_trip]: 'Rajdy przygodowe',
                [COMPETITION_TYPES.rolls]: 'Rolki',
                [COMPETITION_TYPES.triathlon]: 'Triathlon',
                [COMPETITION_TYPES.skiing]: 'Narciarstwo',
                [COMPETITION_TYPES.swiming]: 'Pływactwo',
                [COMPETITION_TYPES.kids]: 'Dziecięce',
                [COMPETITION_TYPES.shooting]: 'Strzelectwo',
                [COMPETITION_TYPES.camp]: 'Obóz',
                [COMPETITION_TYPES.other]: 'Inne',
            },
            competitionTypes: null,
            locationName: '',
        };
    },
    computed: {
        startedTimeHour() {
            return this.form.startedTime?.format('HH:mm') ?? '';
        },
        provisionTimeHour() {
            return this.form.provisionTime?.format('HH:mm') ?? '';
        },
        preStartedTimeHour() {
            return this.form.preStartedTime?.format('HH:mm') ?? '';
        },
        isCoordsCompetition() {
            return this.form.locationLat && this.form.locationLng || this.form.location;
        },
        isEditPage() {
            return this.$route.name === 'panel-competition-setting-info';
        },
    },
    watch: {
        // Need for BaseInputAutocomplete.
        'form.location': {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    this.locationName = this.form.location;
                });
            },
        },
    },
    created() {
        if (this.form.location && this.isCoordsCompetition) {
            this.locationName = this.form.location;
        }
        this.createCompetitionTypes();
    },
    methods: {
        save() {
            // Validate location input.
            if (!this.form.isRemote && !this.isValidateLocation()) {
                this.$set(this.errors, 'location', 'required field');
                // this.errors.location = 'Required field';
                this.$refs.location.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                });
            } else {
                this.$emit('save');
            }
        },
        isValidateLocation() {
            return (
                this.form.location
                && this.form.locationLat
                && this.form.locationLng
            );
        },
        updateCoords(coords) {
            this.form.locationLat = coords.lat;
            this.form.locationLng = coords.lng;
        },
        createCompetitionTypes() {
            const typeList = [];
            for (const type of Object.values(this.types)) {
                typeList.push({
                    text: this.typesNames[type],
                    id: type,
                });
            }
            this.competitionTypes = typeList;
        },
        setTimeInDate(time, target) {
            const timeArray = time.split(':');
            const hour = timeArray[0];
            const minutes = timeArray[1];
            let dateWithTime;
            // Target may be empty. Ex.: startedTime = null
            if (!target) {
                dateWithTime = moment()
                    .hour(hour)
                    .minutes(minutes);
            } else {
                dateWithTime = target
                    .clone()
                    .hour(hour)
                    .minutes(minutes);
            }
            return dateWithTime;
        },
        changeStartedTime(time) {
            const startedTime = this.setTimeInDate(time, this.form.startedTime);
            this.form.startedTime = startedTime;
        },
        changeProvisionTime(time) {
            const provisionTime = this.setTimeInDate(
                time,
                this.form.provisionTime,
            );
            this.form.provisionTime = provisionTime;
        },
        changePreStartedTime(time) {
            const preStartedTime = this.setTimeInDate(
                time,
                this.form.preStartedTime,
            );
            this.form.preStartedTime = preStartedTime;
        },
        cityWithoutSelect: debounce(function(city) {
            this.selectCity(city)
        }, 500),
        selectCity(city) {
            if (typeof city === 'string' && city !== '') {
                this.form.location = city;
                this.form.locationLat = 52.142479791733464;
                this.form.locationLng = 19.423828125000004;
            } else if (city) {
                this.form.location = city.city;
                this.form.locationLat = city.cityLat;
                this.form.locationLng = city.cityLng;
            } else {
                this.form.locationLat = 0;
                this.form.locationLng = 0;
                this.form.location = null;
            }
        },
        onSearchBlur() {
            // Waiting for emit selected value in location input.
            setTimeout(() => {
                this.locationName = this.form.location;
            }, 250);
        },
    },
};
</script>
<style scoped>
.form-input__date {
    color: black;
    border-radius: 4px;
}
.form-input__date >>> .filters-datepicker__calendar-icon {
    top: 13px;
}
.form-input__date >>> .filters-datepicker__wrapper-class {
    margin: 0;
}
.form-input__competition-type--active {
    background-color: var(--color-dark-pastel-green);
}
.form-input__location >>> .vs__selected-options:focus-within .vs__selected {
    opacity: 0 !important;
}

.form-input__location >>> .vs__selected {
    display: flex !important;
    padding-left: 6px;
}

.form-input__location {
    max-height: 40px;
    min-height: 40px;
}

.form-input__location >>> .vs__selected,
.form-input__location >>> .vs__search {
    font-family: 'Open Sans' !important;
    font-size: 14px !important;
    color: var(--color-black) !important;
    position: absolute;
    height: 100%;
    margin: 0;
}

.form-input__location >>> .autocompleter-competition__input {
    transition: all var(--animation-fast);
}

.form-input__hint {
    line-height: 21px;
    margin-top: 16px;
}
.form-input__label {
    margin-bottom: 8px;
}
.form-input__label--positive {
    color: var(--color-light-sage);
}
.form-map {
    overflow: hidden;
}
.form-map__map {
    width: 100%;
    height: 300px;
}
.fade-map-enter {
    max-height: 0;
}

.fade-map-enter-to {
    max-height: 300px;
}

.fade-map-leave-to {
    max-height: 0;
}

.fade-map-enter-active,
.fade-map-leave-active {
    transition: all 0.6s;
}
.form-input__label--competition-type {
    margin-top: 16px;
}
.form-input__hour {
    margin-left: 15px;
}
.form-input__label--duration {
    margin-top: 16px;
    margin-bottom: 16px;
}
.form-input__label--send-results {
    margin-top: 16px;
}
.form-input__email {
    margin-top: 16px;
}
@media (min-width: 992px) {
    .form-input__label--competition-type {
        margin-top: 0;
    }
    .form-input__hour {
        margin-left: 30px;
    }
    .form-input__hint {
        margin-left: 15px;
        margin-top: 0;
    }
    .form-input__email {
        margin-top: 0;
        margin-left: 30px;
    }
}
</style>
