import authentication from './authentication';
import authorization from './authorization';
import bootstrap from './bootstrap';
import props from './props';
import redirect from './redirect';
import api from './api';

export default {
    authentication,
    authorization,
    bootstrap,
    props,
    redirect,
    api,
};
